import React, {useState, useLayoutEffect} from "react";
import createPersistedState from 'use-persisted-state';

import {
  Routes,
  Route,
  NavLink,
  BrowserRouter,
  useLocation,
} from "react-router-dom";

import Home from "./Home";
import Bookmarks from "./Bookmarks";
import About from "./About";
import Terms from "./Terms";
import Privacy from "./Privacy";
import Cookies from "./Cookies";


// for scrolling to top whenever page is changed:
const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 

 

function Inbetween(props) {

	const location = window.location.pathname; // gets current url
	//console.log('loc: ' + location);
	var loc_path = location; // '/gcse/maths/aqa'
	var loc_parts = loc_path.split('/'); // ['', 'gcse', 'maths', 'aqa']

	const [loadingPapers, setLoadingPapers] = useState(true) // #f6aded #f1f1f1

  // Set up local storage (cookies) for each papertype (e.g. GCSE Maths AQA Paper 1 (Higher))
	var usePersStatesPaperType = {}; // works the same as colourDict. Has rows of ' 1: [thing, setThing] ', where number is id of papertype.

  //console.log("paperTypeData:");
  //console.log(props.paperTypeData);
  
    // creates local-storage for 'All Papers'. This is PROBLEMATIC(!!!) because theres a DIFFERENT 'all papers' for each exam board!
    // Can probs fix by adding an 'all papers' in Django for each exam board
    // Sort this out later.
  usePersStatesPaperType[ 0 ] = createPersistedState('All Papers')( false ); 

  for(var grp in props.paperTypeData) {
      // create local-storage for each PaperType (e.g. GCSE Maths AQA Paper 2 Foundation)
      // the trick is that, in Exam.js, paperTypeNum is *also* id of the papertype.
    //var uniqueName = props.paperTypeData[grp].url+'--'+props.paperTypeData[grp].id;
    //console.log('uniqueName: '+uniqueName);
    // what's inside 'createPersistedState()' can be anything as long as its unique! 
    usePersStatesPaperType[ props.paperTypeData[grp].id ] = createPersistedState(props.paperTypeData[grp].id)( false );
  }

  //(usePersStatesPaperType.length === undefined) // ALWAYS undefined!!
  

  /* NEWDATA 6/4/22
	useEffect(() => {
    if (loc_parts[3] !== undefined) {
      console.log('getting papers (USEEFFECT)...');
      setLoadingPapers(true);

      //console.log(props.paperData);

      const fetchData = async () => {
        const result = await axios(
          papersURL+"uk/"+loc_parts[1]+"/"+loc_parts[2]+"/"+loc_parts[3]+"/",
        );
      
        props.setPaperData(result.data);
        console.log('got the papers.');
        setLoadingPapers(false);

      };
    
      fetchData();
  
    }
    
	}, [ loc_parts[3] ]);

  */




  var usePersStatesPaperYear = {};

  for(var obj in props.paperData.papers) {
    //console.log('obj.id: ' + props.paperData.papers[obj].id);
    for(var pt in props.paperData.papers[obj].pprtypes) {
      //console.log('papertype.id: ' + props.paperData.papers[obj].pprtypes[pt].pprtype);
      for(var yrmonth in props.paperData.papers[obj].pprtypes[pt]['yr-months']) {
        //console.log('yrmonth.id: ' + props.paperData.papers[obj].pprtypes[pt]['yr-months'][yrmonth].id);

        usePersStatesPaperYear[ String(props.paperData.papers[obj].pprtypes[pt]['yr-months'][yrmonth].id) ] = createPersistedState(String(props.paperData.papers[obj].pprtypes[pt]['yr-months'][yrmonth].id))( false );
      
      }
    }
    
  } 

  //console.log(JSON.stringify(usePersStatesPaperYear))

  /*
  for (var key in usePersStatesPaperYear) {

    console.log('usePersStatesPaperYear: ' + key + ' ' + usePersStatesPaperYear[key])
  }*/

  /*
  var usePersStatesPaperYear = {};

  for(var grp2 in props.paperData.papers) {
    usePersStatesPaperYear[ String(props.paperData.papers[grp2].url) ] = createPersistedState(String(props.paperData.papers[grp2].url))( false );
  } 
  */



  
  

  

  // states for nav, so can change svg pics.
	const [navAllExams, setNavAllExams] = useState(false); 
	//const [nav, setNav] = useState(false); 
	//const [nav, setNav] = useState(false); 



    // can probs delete this.
    /*
      if (Object.keys(usePersStatesPaperType).length === 0) return (
          <>
              <a href="/"> 
              <div className="logo" id="logo-id">
              <img className="spinning" style={{width: '100px', margin: '20px 0 0 0'}} src={logo} alt="Examoo Logo"/>
               <h1 style={{margin: '-10px 5px 5px 5px', fontWeight: '600', fontSize: '25px', color: 'black', position: 'relative', zIndex: 1000}}>examoo</h1>
              </div>
              </a>
                  <div className="loading">LOADING...</div> 
          </>
      ); 
    */

      return (
        <BrowserRouter>


			  <div className="logo-parent">

          <a href="/"> {/* Used to be <NavLink>, but need <a> to have total page refresh (so that qual, subj aren't remembered.) */}
              <div className="logo" id="logo-id">
                {/* <img rel="preload" style={{width: '85px', margin: '20px 0 0 0'}} src={logo_svg} alt="Examoo Logo"/> */}
                <svg style={{width: '85px', margin: '20px 0 0 0'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 1.9 82.5 63.6" xmlSpace="preserve">
                  <path fill="#404041" d="M75.3,21l0.3,33.3c0,6.2-5.7,11.2-12.6,11.2H27.5c-7,0-12.6-5-12.6-11.2V23.4c0-6.2,5.7-11.2,12.6-11.2h35.5
                    c2.6,0,12.4-1.4,12.4,7.7"/>
                  <path fill="#FFAEEC" d="M59.8,62.9H24.3c-7,0-13.8-5.3-13.8-11.4V20.5c0-6.2,6.9-10.9,13.8-10.9h35.5c2.6,0,22.7-0.7,22.7,7.1
                    c-0.4,6.8-5.2,7.3-8.6,5c0,0-2-1.4-1.8,3.6l0.3,26.4C72.4,57.9,66.8,62.9,59.8,62.9z"/>
                  <path fill="#FFAEEC" d="M70.9,30.9v-8.6c0-7-5.7-12.6-12.6-12.6H22.8c-2.6,0-22.7-0.8-22.7,8c0.5,6.6,6.9,6,10.3,3.4
                    c0,0,0.3,0.7,0.1,6.3l0,4.2L70.9,30.9z"/>
                  <path fill="#FFFFFF" d="M56.6,60H21.1c-7,0-12.6-5-12.6-11.2V18c0-6.2,5.7-11.2,12.6-11.2h35.5c2.6,0,28.9-0.1,25.6,11.9
                    c1.6-5.9-13.2-6.8-13.2,3.8l0.3,26.4C69.2,55,63.6,60,56.6,60z"/>
                  <path fill="none" stroke="#404041" strokeWidth="5" strokeLinecap="round" strokeMiterlimit="10" d="M49.4,29.9
                    c0-3.4,2.7-6.1,6.1-6.1c3.4,0,6.1,2.7,6.1,6.1"/>
                  <path fill="none" stroke="#404041" strokeWidth="5" strokeLinecap="round" strokeMiterlimit="10" d="M17.3,29.7
                    c0-3.4,2.7-6.1,6.1-6.1s6.1,2.7,6.1,6.1"/>
                  <path fill="#58595B" d="M42.3,60l14,0.1c7.3,0,12.9-4.5,12.9-11.4c0-6.3-0.1-8.1-0.1-8.1C61.6,40.6,42.3,52.2,42.3,60z"/>
                  <path fill="#404041" d="M21.6,6.7c-10,0-22.6,4-21.5,11.5c-0.3-2.7,8.4-6.7,8.4,0.2l0,5.6c3.5,3.1,3.8-4.4,11.8-6.1
                    c8.1-1.6,9,3.3,18.8,3.3C52.1,21.2,51,8.7,45,6.7"/>
                  <path fill="#FFAEEC" d="M29.5,54.6h19.1c5.1,0,9.3-4.2,9.3-9.3v0c0-5.1-4.2-9.3-9.3-9.3H29.5c-5.1,0-9.3,4.2-9.3,9.3v0
                    C20.2,50.4,24.4,54.6,29.5,54.6z"/>
                  <circle fill="#404041" cx="29.7" cy="41.6" r="2.3"/>
                  <circle fill="#404041" cx="49.4" cy="41.7" r="2.3"/>
                  <path fill="#404041" d="M75.4,22.5c-0.2,0-2.1-1.8-2.1-1.8s-1.1,0.4-1.1,2.3c0,1.9,0.1,5.5,0.1,5.5c0,0.8,0.7,1.4,1.5,1.4H74
                    c0.8,0,1.4-0.6,1.4-1.4c0,0,0-0.6,0-3.4C75.3,22.2,75.4,22.5,75.4,22.5z"/>
                  <path fill="#404041" d="M58.9,7.2c-0.1,1.5,1.7,2.9,4.1,3c2.3,0.1,4.3-1,4.4-2.6S65.7,2,63.4,1.9C61.1,1.8,59,5.7,58.9,7.2z"/>
                  <path fill="#404041" d="M11.1,8.2c0.2,1.5,2.2,2.6,4.5,2.4c2.3-0.2,4.1-1.7,3.9-3.2c-0.2-1.5-2.5-5.6-4.8-5.4S10.9,6.6,11.1,8.2z"/>
                </svg>
                <h1 style={{margin: '-10px 5px 5px 5px', fontWeight: '600', fontSize: '25px', color: 'black'}}>examoo</h1>
              </div>
          </a>

        </div>


        <div className="fade-in">
          <div className="nav nav-text">
            <div className="flex-container">

              <NavLink to={props.pathNavBar}>
              <div className="flex-item">
                <div className="nav-img">
                  {/* <img src={examsOff} style={{height: '30px'}} alt="All Exams Button" /> */}
                  <div className="nav-img-img" id="nav-img-exams"></div>
                </div>
                <div className="nav-text">All Exams</div>
              </div>
              </NavLink>

              <NavLink to="/bookmarks">
              <div className="flex-item">
                <div className="nav-img">
                  {/* <img src={bmkOff} style={{height: '30px'}} alt="Bookmarks Button" /> */}  
                  <div className="nav-img-img" id="nav-img-bookmarks"></div>
                </div>
                <div className="nav-text">Bookmarks</div>
              </div>
              </NavLink>

              <NavLink to="/about">
              <div className="flex-item" style={{width: '60px'}}>
                <div className="nav-img">
                  {/* <img src={aboutOff} style={{height: '30px'}} alt="About Button" /> */}
                  <div className="nav-img-img" id="nav-img-about"></div>   
                </div>
                <div className="nav-text">About</div>
              </div>
              </NavLink>

            </div>
          </div>

          <div className="nav nav-blur">
          </div>
          <div className="nav nav-colour">
          </div>

          <div className="cookies-message">
            <h1>Cookies 🍪</h1>
            <p>This website uses <span style={{color: 'white'}}>cookies</span> in order to work as intended <span className="cookie-emoji">🍪</span></p>
            <div className="cookies-buttons">
              <button>Reject anyway</button>
              <button>Accept all</button>
            </div>
          </div>



          <div className="content">
            <Wrapper>
              <Routes>
                  <Route exact path="*" element={<Home
                    pathNavBar={props.pathNavBar}
                    setPathNavBar={props.setPathNavBar}
            
                    qualData={props.qualData} 
                    //setQualData={props.setQualData}
                    colourData={props.colourData} 
                    //setColourData={props.setColourData}
                    subjData={props.subjData} 
                    //setSubjData={props.setSubjData}
                    examBoardData={props.examBoardData} 
                    //setExamBoardData={props.setExamBoardData}
                    paperTypeData={props.paperTypeData} 
                    //setPaperTypeData={props.setPaperTypeData}
                    testData={props.testData} 
                    //setTestData={props.setTestData}
                    paperData={props.paperData} 
                    //setPaperData={props.setPaperData}

                    usePersStatesPaperType={usePersStatesPaperType}
                    usePersStatesPaperYear={usePersStatesPaperYear}
                    loadingPapers={loadingPapers}

                  /> }/>

                  <Route path="/bookmarks" element={<Bookmarks

                    qualData={props.qualData} 
                    colourData={props.colourData} 
                    subjData={props.subjData} 
                    examBoardData={props.examBoardData} 
                    paperTypeData={props.paperTypeData} 
                    paperData={props.paperData} 

                    usePersStatesPaperType={usePersStatesPaperType}
                    usePersStatesPaperYear={usePersStatesPaperYear}
                    loadingPapers={loadingPapers}
                  
                  />}/>

                  <Route path="/about" element={<About/>}/>

                  <Route path="/terms" element={<Terms/>}/>
                  <Route path="/privacy" element={<Privacy/>}/>
                  <Route path="/cookies" element={<Cookies/>}/>
              </Routes>
            </Wrapper>
          </div>
        </div>
        </BrowserRouter>
      );

	
}
 
export default Inbetween;