import React, { useState, useEffect } from "react";
import axios from "axios";
//import { useCookies } from "react-cookie";
//import createPersistedState from 'use-persisted-state';

//import logo from "./logo.png";
import dropdown from "./dropdown.svg";
//import tick from "./tick.svg";
import vertical from "./vertical-line.svg";
import meat from "./meats_by_dre.png";

import socialDiscord from "./social-discord.svg";
import socialInsta from "./social-insta.svg";
import socialTiktok from "./social-tiktok.svg";

import Cookies from 'js-cookie';

import {Adsense} from '@ctrl/react-adsense';
 
import {
  NavLink,
  useLocation,
} from "react-router-dom";

//import { removeDirAndFiles } from "minio/dist/main/helpers"; // not sure why this line is here??

//import BoardPaper from "./BoardPaper";

function getSubj(str) {
	//console.log(str);
	if (str===undefined) {
		return('Subject');
	} else {
		return(str);
	}
}

function getUrlPath(loc_parts, num, end_str) {
	// e.g. "/".concat(String(loc_parts[1])).concat('/').concat(String(loc_parts[2])).concat('/aqa')
	let urlString = '/';
	
	for (let i = 1; i <= num; i++) {
		urlString = urlString + String(loc_parts[i]) + '/';
 	}
	urlString = urlString + end_str;
	
	return(urlString);
}


function getUrlPathHash(loc_parts, num, end_str) {
	let urlString = '';
	
	for (let i = 1; i <= num; i++) {
        //if (i>1) {
        //}
		urlString = urlString + '/' + String(loc_parts[i]);
 	}
	urlString = urlString + '#' + end_str;
	
	return(urlString);
}

const herokuOrLocal = true;
var baseURL = "";
var papersURL = "";

if (herokuOrLocal === true) {
	baseURL = "https://papersrepo.herokuapp.com/api/"; // https://papersrepo.herokuapp.com/api/ , http://localhost:5000/api
	papersURL = "https://papersrepo.herokuapp.com/paper/";	
} else {
	baseURL = "http://localhost:5000/api/"; // https://papersrepo.herokuapp.com/api/ , http://localhost:5000/api
	papersURL = "http://localhost:5000/paper/";
}



function showBoxN(loc_parts_n) {
    if (loc_parts_n === undefined || loc_parts_n === '') {
        return(false);
    } else {
        return(true);
    };
}


/*
function getDataAxios(str) {

	var james = '';

	var urlll = baseURL+str;
	console.log('URL:'+urlll)

	axios.get(baseURL+str)
		.then((response) => {
			console.log('respdata '+response.data);
			james = response.data;
			console.log('james '+james);
		})
		.catch(error =>
			console.log(error)
		);

	if (!james) {
		return null;
	} else {
		return james;
	}

}
*/


//const qualDataRUFF = getDataAxios('qualifications');

//console.log('Data returned: '+qualDataRUFF);

//const the_logo = document.getElementById('logo-id');


















function Exams(props) {

	// initially, get all info from URL. Then from click (which changes URL)!

	const location = useLocation(); // gets current url
	var loc_path = location.pathname + location.hash; // '/gcse/maths/aqa' + '1-H'
	var loc_parts = loc_path.replace('#', '/').split('/'); // ['', 'gcse', 'maths', 'aqa']   // OG: loc_path.split('/')

	const [toggleQual, setToggleQual] = useState(false);
	const [toggleSubj, setToggleSubj] = useState(false);
	const [toggleBoard, setToggleBoard] = useState(false);
	const [togglePaper, setTogglePaper] = useState(false);

	const [subjEnabled, setSubjEnabled] = useState(false); 
	const [paperTypeEnabled, setPaperTypeEnabled] = useState(false);
	const [examBoardEnabled, setExamBoardEnabled] = useState(false);

	const [box1Height, setBox1Height] = useState(300);
	const [box2Height, setBox2Height] = useState(300);


	// currently-selected URLs of qual, subj
	const [regionUrl, setRegionUrl] = useState('uk');
	const [qualUrl, setQualUrl] = useState(loc_parts[1]);
	const [subjUrl, setSubjUrl] = useState(loc_parts[2]);
	const [examBoardUrl, setExamBoardUrl] = useState(loc_parts[3]);
	const [paperTypeUrl, setPaperTypeUrl] = useState(loc_parts[4]);
	const [papersFiltered, setPapersFiltered] = useState(false);

	// currently-selected Names of qual, subj
	const [qualName, setQualName] = useState('Qualification'); //qualData.find(x => x.url === qualUrl).name  //'Choose Qualification:'
	const [subjName, setSubjName] = useState('Subject');
	const [examBoardName, setExamBoardName] = useState('Exam board');
	const [paperTypeName, setPaperTypeName] = useState('All papers');

	const [qualNum, setQualNum] = useState(0); // id of qualification (e.g. GCSE has id=1, A-Level has id=3)
	const [subjNum, setSubjNum] = useState(0);
	const [examBoardNum, setExamBoardNum] = useState(0);
	const [paperTypeNum, setPaperTypeNum] = useState(0);

	const [subjColour, setSubjColour] = useState('#eec9e8') // #f6aded #f1f1f1

	//const [loadingPapers, setLoadingPapers] = useState(true) // #f6aded #f1f1f1


	//const [count, setCount] = useState(0);
	//const [count, setCount] = useStickyState('true', "count"); JWC


	//const [cookies, setCookie] = useCookies(['user']);
	
	/*
	function handleCookie() {
		setCookie("user", "gowtham", {
		  path: "/"
		});
	  }
	*/




	useEffect(() => {
		// need all this to not get error (.clientHeight gives error because select-box-1 hasn't loaded yet)
		var boxheighttemp = document.getElementById('select-box-1').clientHeight;

		if (!boxheighttemp) {
			boxheighttemp = 0;
		}

		setBox1Height(boxheighttemp + 50);
	}, [toggleQual, toggleSubj]);

	
	useEffect(() => {
		var boxheighttemp = document.getElementById('select-box-2').clientHeight;

		if (!boxheighttemp) {
			boxheighttemp = 0;
		}

		setBox2Height(boxheighttemp + 102); // + 150
	}, [toggleBoard, togglePaper]);
	
	

	props.setPathNavBar(loc_path); // source of a console error (I think)


	/*
	var colourDictTest = {
		1: "#FFC001",
		2: "#FF0000", 
		3: "#00FF00", 
	};
	*/


	// just directly use props.colourData[] instead..?
	var colourDict = {};

	
	for(var group in props.colourData) {
		colourDict[ props.colourData[group].id ] = props.colourData[group].hex_colour;
	}

	//console.log('1' + props.colourData);
	//console.log('2' + colourDict);


	function updateQual() {
		//setQualUrl(loc_parts[1]);  // is this necessary?

		var thing = props.qualData.find(x => x.url === qualUrl);

		setQualName(thing.name);
		setQualNum(thing.id);
	}

	function updateSubj() {
		//setQualUrl(loc_parts[1]);  // is this necessary?
		//setSubjUrl(loc_parts[2]);  // is this necessary?

		//var thing = props.subjData.filter(x => x.qualification === qualNum && x.url === subjUrl)[0];
		var thing0 = props.qualData.find(x => x.url === qualUrl);
		var thing = props.subjData.find(x => x.qualification === thing0.id && x.url === subjUrl);

		setSubjName(thing.name);
		setSubjNum(thing.id);
		setSubjColour(colourDict[thing.colour])
	}

	function updateExamBoard() {
		//setQualUrl(loc_parts[1]);  // is this necessary?
		//setSubjUrl(loc_parts[2]);  // is this necessary?
		//setExamBoardUrl(loc_parts[3]); // is this necessary?
		
		// OG:
		/*
		var thing = props.subjData.filter(x => x.qualification === qualNum && x.url === subjUrl)[0];
		var thing2 = props.examBoardData.find(x => x.url === examBoardUrl);
		setExamBoardName(thing.name);
		setExamBoardNum(thing.id);
		*/
		
		var thing0 = props.qualData.find(x => x.url === qualUrl);

		var thing = props.subjData.find(x => x.qualification === thing0.id && x.url === subjUrl); // (x => x.url === subjUrl)

		//console.log(subjNum, subjNum)

		var thing2 = props.examBoardData.find(x => x.subject === thing.id && x.url === examBoardUrl); // using thing.id instead of subjUrl (because subjUrl hasn't updated yet; it's still 0)
		//var thing = props.examBoardData.filter(x => x.subject === subjNum && x.url === examBoardUrl)[0];
		
		setExamBoardName(thing2.name);
		setExamBoardNum(thing2.id);

		// used to be done by allPapersFunction() 
		var thing3 = props.paperTypeData.find(x => x.exam_board === thing2.id  && x.order === 0); // get 'All Papers' papertype
		setPaperTypeNum(thing3.id);
	}

	function updatePaperType() {
		//setPaperTypeUrl(loc_parts[4]); // is this necessary?

		var thing0 = props.qualData.find(x => x.url === qualUrl);
		var thing = props.subjData.find(x => x.qualification === thing0.id && x.url === subjUrl); // (x => x.url === subjUrl)

		//console.log(subjNum, subjNum)

		var thing2 = props.examBoardData.find(x => x.subject === thing.id && x.url === examBoardUrl); // using thing.id instead of subjUrl (because subjUrl hasn't updated yet; it's still 0)

		var thing3 = props.paperTypeData.find(x => x.exam_board === thing2.id && x.url.replace('-','') === paperTypeUrl);

		//var thing3 = props.paperTypeData.find(x => x.url === paperTypeUrl);
		//var thing = props.paperTypeData.filter(x => x.examboard === examBoardNum && x.url === paperTypeUrl)[0];
		// ALWAYS Gets aqa maths, because 'find()' always get the first one!
		
		setPaperTypeName(thing3.name);
		setPaperTypeNum(thing3.id);
	}

	function resetTogglesExcept(n) {

		if (n === 1) {
			setToggleSubj(false);
			setToggleBoard(false);
			setTogglePaper(false);

		} else if (n === 2) {
			setToggleQual(false);
			setToggleBoard(false);
			setTogglePaper(false);

		} else if (n === 3) { 
			setToggleQual(false);
			setToggleSubj(false);
			setTogglePaper(false);
		
		} else if (n === 4) { 
			setToggleQual(false);
			setToggleSubj(false);
			setToggleBoard(false);
		}
	}


	function allPapersFunction() {
		// set paperTypeNum to the id of 'All PaperZ' papertype. 
		// Therefore, 'All papers' bookmark is actually the 'All PaperZ' bookmark.

		//var graham = props.paperTypeData.find(item => (item.exam_board === props.examBoardData.find(x => x.url === examBoardUrl).id && item.order === 0));
		//setPaperTypeNum(graham.id);

		// NEW (June 2022): Fixed 'All Exams -> Bookmarks -> All Exams' bug.
		/*
		var thing0 = props.qualData.find(x => x.url === qualUrl);
		var thing = props.subjData.find(x => x.qualification === thing0.id && x.url === subjUrl); // (x => x.url === subjUrl)
		var thing2 = props.examBoardData.find(x => x.subject === thing.id && x.url === examBoardUrl); // using thing.id instead of subjUrl (because subjUrl hasn't updated yet; it's still 0)
		var thing3 = props.paperTypeData.find(x => x.exam_board === thing2.id && x.order === 0);

		setPaperTypeNum(thing3.id);
		*/
		
	};

	// faster function for when clicking on exam board
	// if doing above instead, the entire page refreshes.
	function allPapersFunctionClick(ebn) {
		setPaperTypeNum(props.paperTypeData.find(item => (item.order === 0 && item.exam_board === ebn)).id);
	};



	useEffect(() => {
		if (loc_parts[1]) { // qual already chosen
			updateQual();
			setSubjEnabled(true);
			setToggleSubj(true);
		} else {
			setToggleQual(true);
		}

		if (loc_parts[2]) { // subj already chosen
			updateSubj();
			setExamBoardEnabled(true);
			setToggleSubj(false);
			setToggleBoard(true);
		}

		if (loc_parts[3]) {
			updateExamBoard();
			setPaperTypeEnabled(true);
			setToggleSubj(false);
			setToggleBoard(false);
			//papersFromServer("uk/"+loc_parts[1]+"/"+loc_parts[2]+"/"+loc_parts[3]+"/");
		}

		if (loc_parts[4]) {
			updatePaperType();
			setPapersFiltered(true);
		} else {
			//setTimeout(allPapersFunction, 300); // functionality now included in updateExamBoard();
		}
		

	}, []);


	// Cleanup - needed for when effect references an external data source (to remove memory leak!)
	/*
	useEffect(() => {
		function something(dataa, urll) {
			setQualName(
				dataa.find(x => x.url === urll).name
			);
		};

		something(props.qualData, qualUrl);

		// how to clean up after the effect:
		return function cleanup() {

		};

	}, [props.qualData, qualUrl]);
	*/






	useEffect(() => {
		if (examBoardNum !== 0) {
			var examBoardNameTemp = "• "+examBoardName;
		} else {
			examBoardNameTemp = "";
		}
		
		if (qualName !== 'Qualification' && subjName !== 'Subject') {
			document.title = qualName+" "+
							subjName+" "+
							examBoardNameTemp+
							" • All Past Papers • Examoo";
		} else {
			document.title = "Get GCSE, AS, A-Level Past Papers Now! • Examoo"; //MAYBE
		}
	}, [qualName, subjName, examBoardName]);

	


	/*
	useEffect(() => {
		console.log('getting papers (USEEFFECT)...');
		//console.log(props.paperData);

		axios.get(papersURL+"uk/"+loc_parts[1]+"/"+loc_parts[2]+"/"+loc_parts[3]+"/")
			.then(function (response) {
				props.setPaperData(response.data);
				console.log('got the papers.');
			})
			.catch(function (error) {
				// handle error
				console.log(error);
				console.log('DIDNT GET the papers.');
			});
	}, [examBoardNum]);
	*/

	// papersFromServer("uk/"+loc_parts[1]+"/"+loc_parts[2]+"/"+item.url+"/");


	function resetFields(n) {

		if (n >= 1) {
			// reset paper filter (to 'all papers')
			setPaperTypeName('All papers');
			setPaperTypeNum(0); // Not sure this is ever used?? Url is used instead
			setPapersFiltered(false);

			if (n >= 2) {
				// reset exam board
				setExamBoardName('Exam board');
				setExamBoardNum(0);

				if (n >= 3) {
					// reset subject
					setSubjName('Subject');
					setSubjNum(0);

					if (n >= 4) {
						// reset qualification
						setQualName('Qualification');
						setQualNum(0);
						setToggleQual(true);
						setSubjEnabled(false);
					}
				}
			}
		}
	}

	/*
	setQualName(
		qualData.find(x => x.url === qualUrl).name
	);
	*/

	// This causes infinite loop, because setting a state re-renders the page.
	//setCount(count+1); 
	
	// This is fine, but unneccessary because you'd just do setState(1) originally anyway.
	// (HOOK RULES Docs: "Don’t call Hooks inside loops, conditions, or nested functions")
	/*
	useEffect(() => { 
		setCount(count+1);
	}, []);
	*/
	

	function papersFromServer(url_ending) {
		console.log('getting papers...');
		//console.log(props.paperData);

		axios.get(papersURL+url_ending)
			.then(function (response) {
				//props.setPaperData(response.data);
				var paperrdataa = response.data;

				console.log('got the papers.');
				console.log(paperrdataa);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			});
	}

	//papersFromServer(papersURL);


	//console.log(testData.ruffvar);
	//console.log(paperData.papers);

	//console.log(qualUrl);
	//const quadSpec = qualData.find(x => x.url === qualUrl).name; // get qual/subj/etc Name from URL.
	//console.log(quadSpec);


	// Turn these into FUNCTIONS ! or useEffect
	if (props.subjData) {
		var subjDataFilter = props.subjData.filter(
			item => item.qualification === qualNum
		);
	} else {
		subjDataFilter = null;		
	};
  	//console.log('subj filtered!'); // this shows how often it runs!

  	if (props.examBoardData) {
		var examBoardDataFilter = props.examBoardData.filter(
			item => item.subject === subjNum
		);
	} else {
		examBoardDataFilter = null;			
	};

	if (props.paperTypeData) {
		var paperTypeDataFilter = props.paperTypeData.filter(
			item => (item.exam_board ===  examBoardNum && item.order !== 0) // && item.order !== 0
		);
	} else {
		paperTypeDataFilter = null;		
	};






	/*
    const [runManyy, setRunManyy] = useState(0);

	useEffect(() => {
		if (props.paperTypeData) {
			allPapersFunction();
		} else {
			setRunManyy(runManyy + 1);
			// keep doing this useEffect until bookmarkArray exists (only seems to need one run)
		}

	}, [runManyy]);
	*/

	//const useCounterState = createPersistedState('count');
	//const [count, setCount] = useCounterState(false);



	/*
	var paperDict = { // get paper name from filename
		"none-pp.pdf": "Past* Paper",
		"none-qp.pdf": "Past* Paper",
		"none-ms.pdf": "Mark* Scheme",
		"pre.PDF": "Pre-release Material",
		"pp.PDF": "Past Paper",
		"qp.PDF": "Past Paper",
		"cards.PDF": "Question Cards",
		"sample.PDF": "Sample",
		"ms.PDF": "Mark Scheme",
		"er.PDF": "Exam Report",
		"com.PDF": "Commentary",
		"ins.PDF": "Insert",
		"in.PDF": "Insert",
		"sc.PDF": "Score",
		"sf.mp3": "Sound File",
		"sf.ZIP": "Sound Files (Separated)",
		"tr.PDF": "Transcript",
		"t3.PDF": "Transcript",
		"db.PDF": "Insert",
		"ex.PDF": "Example Responses",
		"notice.PDF": "Error Notice",
		"err.PDF": "Error Notice",
		"tn.PDF": "Teachers' Notes",
		"maths-in-science.PDF": "Maths In Science",
		"neastudent.PDF": "Student Booklet",
		"neateacher.PDF": "Teacher Booklet",
	};
	*/

	var paperDict = { // get paper name from filename
		"none-pp": "Past* Paper",
		"none-qp": "Past* Paper",
		"none-ms": "Mark* Scheme",
		"pre": "Pre-release Material",
		"pp": "Past Paper",
		"qp": "Past Paper",
		"qpr": "Past Paper (R)",
		"qm": "Past Paper & MS",
		"qm2": "Past Paper & MS 2",
		"qm3": "Past Paper & MS 3",
		"ch": "Changes",
		"ch2": "More Changes",
		"chz": "Changes (.zip)",
		"cards": "Question Cards",
		"sample": "Sample",
		"ms": "Mark Scheme",
		"msr": "Mark Scheme (R)",
		"rep": "Exam Report",
		"repr": "Exam Report (R)",
		"com": "Commentary",
		"ins": "Insert",
		"in": "Insert",
		"per": "Periodic Table",
		"fo": "Formula Book",
		"sb": "Source Booklet",
		"bo": "Booklet",
		"ma": "Map",
		"anth": "Poetry Anthology",
		"da": "Data Sheet",
		"sc": "Score",
		"cc": "Role Play",
		"rp": "Role Play",
		"sf": "Sound File",
		"sfz": "Sound Files (.zip)",
		//"sf.zip": "Sound Files (Separated)",
		"tr": "Transcript",
		"t3": "Transcript",
		"db": "Teacher's Booklet",
		"hint": "Exam Hints",
		"chk": "Revision Checklist",
		"ex": "Example Responses",
		"exr": "Example Responses (R)",
		"ex2": "Example Responses 2",
		"ex3": "Example Responses 3",
		"notice": "Error Notice",
		"err": "Error Notice",
		"er": "Error Notice",
		"tn": "Teacher's Notes",
		"maths-in-science": "Maths In Science",
		"neastudent": "NEA Student Booklet",
		"neateacher": "NEA Teacher Booklet",
		"nea": "NEA Marking Criteria",
		"t": "translation",
		"neacrit": "NEA Marking Criteria",
		"crit": "Assessment Criteria",
		"spec": "Specification",
		"specadd": "Specif. Addendum",
		"guid": "Question Guidance",
        "gloss": "Glossary",
        "sa": "Sample",
        "sa1": "Sample 1",
        "sa2": "Sample 2",
        "mssfz": "Mark Scheme (Audio)",
        "mo": "Movie",
        "dic": "Dictation",
        "dica": "Dictation A",
        "dicb": "Dictation B",
        "faq": "FAQ",
		"man": "Mandarin .mp3",
		"can": "Cantonese .mp3",
		"comm": "Command Words",
		"code": "Code Files",
	};

	//localStorage.setItem("ruff2", "true");
	//const ruff_store = localStorage.getItem("ruff");


	// CSS (if want to depend on states)
	const seeSS = `
		.buttonnn {
			
			background: lightgrey;
		}
	`


	//if (!props.paperData) return null; 







	return (
		<>
		<style>{seeSS}</style>

		<div className="exams-flex">

		<div className="sidebar sidebar-left">

			{paperTypeEnabled
			?
			<div className="fade-in">
			<p><b>Ads</b></p>
			<img src={meat} alt="Meat crisps" />
			</div>
			:
			<>
			</>
			}

		</div>

		<div className="centrebar">

		{/* <button onClick={handleCookie}>Set Cookie</button> 
		<button onClick={localStorage.setItem("ruff", "true")}>Set LocalStorage</button>

		<div className="buttonnn" onClick={() => setCount(!count)} style={{color: count ? 'green' : 'red'}}>Toggle</div> */}

		<div className="bg-box-parent"> {/* Background box */}
			<div className="box select-box" id="bg-box-1" style={{ height: box1Height }}></div>
			<div style={{ position: 'relative', margin: '0px auto', width: '30px' }}>
				<div className="triangle"></div>
			</div>
		</div>
		<div className="box select-box" id="select-box-1">
			<div>
				{/* Qualifications - dropdown header */}
				<button className="qual-select" style={{transform: 'translateY(0px)', paddingTop: '14px'}} onClick={() => { setToggleQual(!toggleQual); resetTogglesExcept(1);}} role="checkbox" aria-checked={toggleQual} aria-labelledby="chk-qual-label">
					{qualName} {/* cookies.user */} <img className="dropdown-img" src={dropdown} style={{transform: toggleQual ? 'translate(3px, -3px) rotate(180deg)' : 'translate(3px, -3px)'}} alt="Dropdown icon" />  
				</button>
				<label id="chk-qual-label" style={{display: 'none'}}>Current qualification: {qualName}. Press this to toggle whether Qualification selection (e.g. GCSE, A-Level) is open or not.</label>

				{/* Qualifications */}
				<div className="choice-div" style={{display: toggleQual ? 'block' : 'none' }}>
					{props.qualData.map(item =>
						{return item.url !== 'as' ? // create newline for as/a-level/ial
							<NavLink className="grid-item" key={item.id} to={item.url} onClick={() => { setToggleQual(!toggleQual); setToggleSubj(true); setQualName(item.name); setQualNum(item.id); setSubjColour('#eec9e8'); resetFields(3); setSubjEnabled(true); setExamBoardEnabled(false); setPaperTypeEnabled(false);} }>
								<div className="qual-title qual-title-pink">{item.name}</div>
							</NavLink>
						:
							<>
							<br/>
							<NavLink className="grid-item" key={item.id} to={item.url} onClick={() => { setToggleQual(!toggleQual); setToggleSubj(true); setQualName(item.name); setQualNum(item.id); setSubjColour('#eec9e8'); resetFields(3); setSubjEnabled(true); setExamBoardEnabled(false); setPaperTypeEnabled(false);} }>
								<div className="qual-title qual-title-pink">{item.name}</div>
							</NavLink>
							</>
						}
					)}
				</div>
			</div>

			<hr/>

			<div>
				{/* Subjects - dropdown header */}
				<button className="qual-select" style={{paddingTop: '6px'}} onClick={() => { setToggleSubj(!toggleSubj); resetTogglesExcept(2);} } role="checkbox" aria-checked={toggleSubj} aria-labelledby="chk-subj-label">
					<span style={{color: subjEnabled ? 'black' : 'lightgrey' }}><span className="subj-circle subj-circle-title" style={{color: subjColour}}>•</span>{subjName}</span> <img className="dropdown-img" src={dropdown} style={{transform: toggleSubj ? 'translate(3px, -3px) rotate(180deg)' : 'translate(3px, -3px)', display: subjEnabled ? 'inline' : 'none' }} alt="Dropdown icon"/>  
				</button>
				<label id="chk-subj-label" style={{display: 'none'}}>Current subject: {subjName}. Press this to toggle whether Subject selection (e.g. Maths, English, Sciences) is open or not.</label>

				{/* Subjects */}
				<div className="choice-div" style={{display: toggleSubj ? 'block' : 'none' }}>
					{subjDataFilter.map(item =>
							<NavLink className="grid-item" key={item.id} to={getUrlPath(loc_parts, 1, item.url)} onClick={() => { setToggleSubj(!toggleSubj); setToggleBoard(true); setSubjName(item.name); setSubjNum(item.id); setSubjColour(colourDict[item.colour]); resetFields(2);  setExamBoardEnabled(true); setPaperTypeEnabled(false); } }>
									<div className="qual-title subj-title"> <span className="subj-circle" style={{color: colourDict[item.colour]}}>•</span> {item.name} </div>
							</NavLink>
					)}
				</div>
					
			</div>
		</div>



		<div className="bg-box-parent">
			<div className="box select-box bg-box-2" style={{background: 'white', height: box2Height, boxShadow: "none", }}></div>
			<div className="box select-box bg-box-2" style={{display: showBoxN(loc_parts[2]) ? 'block' : 'block', background: subjColour.concat('80'), height: box2Height }}></div>
			<div style={{ position: 'relative', margin: '0px auto', width: '30px' }}>
				<div className="triangle" style={{ background: 'white' }}></div>
				<div className="triangle" style={{ background: subjColour.concat('80') }}></div>
			</div>
		</div>
		<div className="box select-box" id="select-box-2">
				<div>
						{/* Exam Boards - Dropdown      style={{visibility: showBoxN(loc_parts[2]) ? 'visible' : 'hidden'}}   */}
						<button className="qual-select" style={{paddingTop: '23px'}} onClick={() => { setToggleBoard(!toggleBoard); resetTogglesExcept(3); }} role="checkbox" aria-checked={toggleBoard} aria-labelledby="chk-exam-board-label">
						<span style={{opacity: examBoardEnabled ? 1 : 0.2 }}>{examBoardName}</span> <img className="dropdown-img" src={dropdown} style={{transform: toggleBoard ? 'translate(3px, -3px) rotate(180deg)' : 'translate(3px, -3px)', display: examBoardEnabled ? 'inline' : 'none' }} alt="Dropdown icon"/>  
						</button>
						<label id="chk-exam-board-label" style={{display: 'none'}}>Current exam board: {examBoardName}. Press this to toggle whether Exam Board selection (e.g. AQA, Edexcel, OCR) is open or not.</label>

						{/* Exam Boards */}
						<div className="choice-div" style={{display: toggleBoard ? 'block' : 'none' }}>
							{examBoardDataFilter.map(item =>
									<NavLink className="grid-item" key={item.id} to={getUrlPath(loc_parts, 2, item.url)} onClick={() => { window.gtag('event', 'conversion', {'send_to': 'AW-10904777324/xJOGCN3vt74DEOzk5s8o'}); setToggleBoard(!toggleBoard); setExamBoardName(item.name); setExamBoardNum(item.id); resetFields(1); setPaperTypeEnabled(true); allPapersFunctionClick(item.id) } }> {/* papersFromServer("uk/"+loc_parts[1]+"/"+loc_parts[2]+"/"+item.url+"/"); */}
										<div className="qual-title board-title">{item.name}</div>
									</NavLink>
							)}
						</div>

				</div>

				<hr/>

				<div>
						<div className="qual-select" style={{paddingTop: '5px'}}>
							<span className="papertype-flex" style={{opacity: paperTypeEnabled ? 1 : 0.2 }}>
								{/* Bookmark button */}
								<button role="checkbox" aria-checked={props.usePersStatesPaperType[paperTypeNum][0]} aria-labelledby="chk1-label" className="papertype-flex-item" onClick={ Cookies.get('rcl_consent_given') ?  () => {props.usePersStatesPaperType[paperTypeNum][1](!props.usePersStatesPaperType[paperTypeNum][0])} : undefined } alt="Add or remove this set of papers from the Bookmarks page"> {/* This is just: setBookmarkToggle(!bookmarkToggle) */}
									{/* {paperTypeNum} // for testing whether 'all papers' bookmark has id of 'All PaperZ' */}
									{props.usePersStatesPaperType[paperTypeNum][0] && paperTypeEnabled
									?
									<svg className="bookmark-menu" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
										width="41.2px" height="60.5px" viewBox="0 0 41.2 60.5" xmlSpace="preserve">
										<g>
											<path fill="#000000" d="M37,58L22.2,41.9c-0.8-0.9-2.1-0.9-2.9,0L4.2,58.1C3.4,58.9,2,58.4,2,57.2V9.5C2,5.3,5.3,2,9.5,2h22.3
												c4.1,0,7.5,3.3,7.5,7.5v47.7C39.2,58.3,37.8,58.9,37,58z"/>
											<path fill="#000000" d="M3.3,60.5c-0.8,0-1.6-0.3-2.2-0.9c-0.7-0.6-1-1.5-1-2.4V9.5C0,4.3,4.3,0,9.5,0h22.3c5.2,0,9.5,4.3,9.5,9.5
												v47.7c0,1.8-1.5,3.3-3.3,3.3c-0.9,0-1.8-0.4-2.4-1.1L20.7,43.2L5.6,59.4C5,60.1,4.2,60.5,3.3,60.5z M9.5,4C6.5,4,4,6.5,4,9.5v45.9
												l13.8-14.8c0.8-0.8,1.8-1.3,2.9-1.3c1.1,0,2.2,0.5,2.9,1.3l13.6,14.7V9.5c0-3-2.5-5.5-5.5-5.5H9.5z"/>
										</g>
									</svg>
									:
									<svg className="bookmark-menu" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
										width="41.2px" height="60.5px" viewBox="0 0 41.2 60.5" xmlSpace="preserve">
										<path fill="#000000" d="M3.3,60.5c-0.8,0-1.6-0.3-2.2-0.9c-0.7-0.6-1-1.5-1-2.4V9.5C0,4.3,4.3,0,9.5,0h22.3c5.2,0,9.5,4.3,9.5,9.5
											v47.7c0,1.8-1.5,3.3-3.3,3.3c-0.9,0-1.8-0.4-2.4-1.1L20.7,43.2L5.6,59.4C5,60.1,4.2,60.5,3.3,60.5z M9.5,4C6.5,4,4,6.5,4,9.5v45.9
											l13.8-14.8c0.8-0.8,1.8-1.3,2.9-1.3c1.1,0,2.2,0.5,2.9,1.3l13.6,14.7V9.5c0-3-2.5-5.5-5.5-5.5H9.5z"/>
									</svg>
									}
								</button>
								<label id="chk1-label" style={{display: 'none'}}>Add or remove this set of papers from the Bookmarks page</label>

								<button className="papertype-flex-item" onClick={() => {setTogglePaper(!togglePaper); resetTogglesExcept(4); }} role="checkbox" aria-checked={togglePaper} aria-labelledby="chk-papertype-label" style={{marginTop: '-6.5px'}}>
									<span className="papertype-name">
										<span>{paperTypeName}</span> 
									</span>
									<img className="dropdown-img" src={dropdown} style={{transform: togglePaper ? 'translate(8px, -3px) rotate(180deg)' : 'translate(8px, -3px)', display: paperTypeEnabled ? 'inline' : 'none' }} alt="Dropdown icon"/>  
								</button>

								<label id="chk-papertype-label" style={{display: 'none'}}>Current set of papers: {paperTypeName}. Press this to toggle whether Paper set selection (e.g. Paper 1, Paper 2, All Papers) is open or not.</label>
							</span> 
							
						</div>

						{/* Papertypes */}
						<div style={{display: showBoxN(loc_parts[3]) ? 'block' : 'none'}}>
							<div className="choice-div choice-div-papertypes" style={{display: togglePaper ? 'block' : 'none' }}>

										<NavLink className="grid-item" key='0' to={getUrlPath(loc_parts, 3, '')} onClick={() => { setTogglePaper(!togglePaper); setPaperTypeName('All papers'); setPaperTypeNum(0); setPapersFiltered(false); allPapersFunctionClick(examBoardNum) } }> {/* allPapersFunctionClick(examBoardNum) */}
											<div className="qual-title papertype-title papertype-title-allpapers">All papers</div>
										</NavLink>


								{paperTypeDataFilter.map(item =>
										<NavLink  className="grid-item" key={item.id} to={getUrlPathHash(loc_parts, 3, item.url.replace('-',''))} onClick={() => { setTogglePaper(!togglePaper); setPaperTypeUrl(item.url.replace('-','')); setPaperTypeName(item.name); setPaperTypeNum(item.id);  setPapersFiltered(true);  } }>
											<div className="qual-title papertype-title">{item.name}</div>
										</NavLink>
								)}
							</div>
						</div>
				</div>
		</div>





		{/* <div className="how-to" style={{display: showBoxN(loc_parts[3]) ? 'none' : 'block' }}>Get papers by selecting above!</div> */}

		<div className="box box-papers shadow-filter" style={{display: 'block', background: subjColour, padding: '15px 0 7px 0px', marginBottom: '65px'}}>
			<div style={{ position: 'relative', margin: '0px auto', width: '30px', zIndex: -10 }}>
				<div className="triangle" style={{ background: subjColour, transform: 'scaleX(1.4) translateY(-23px) rotate(45deg)' }}></div>
			</div>
			{/*
			<svg style={{width: '200px', opacity: '0.3', position: 'absolute', left: '30%', top: '20px'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				 viewBox="0 0 103.8 82.9"  xmlSpace="preserve">
				<path fill="none" stroke="#FFFFFF" stroke-width="4" stroke-miterlimit="10" d="M60.2,70.6l-30.1,9.8c-4.8,1.6-10-1.1-11.6-5.9
					L2.4,24.9c-1.6-4.8,1.1-10,5.9-11.6l30.1-9.8C43.2,2,48.4,4.7,50,9.5L66.1,59C67.7,63.8,65,69,60.2,70.6z"/>
				<path fill="none" stroke="#FFFFFF" stroke-width="4" stroke-miterlimit="10" d="M45.8,4.3l1.3,0l20.5,0.1c5.1,0,9.2,4.2,9.2,9.2
					l-0.2,52.1c0,5.1-4.2,9.2-9.2,9.2L47,74.8"/>
				<path fill="none" stroke="#FFFFFF" stroke-width="4" stroke-miterlimit="10" d="M30,6.5c0.4-0.4,0.9-0.7,1.4-1
					c1.4-0.8,3-1.3,4.6-1.3"/>
				<path fill="none" stroke="#FFFFFF" stroke-width="4" stroke-miterlimit="10" d="M56.3,4.7c0.4-0.4,0.9-0.8,1.4-1.2
					c2.3-1.6,5.4-2,8.2-1l29.8,10.6c4.8,1.7,7.3,7,5.6,11.7L83.9,74c-1.7,4.8-7,7.3-11.7,5.6l-10.5-3.7l-1.8-0.6"/>
			</svg>
			*/}
			<div className="how-to" style={{display: showBoxN(loc_parts[3]) ? 'none' : 'block' }}>Get <span>{qualNum ? qualName : ''} {subjNum ? subjName : ''} past papers</span> by selecting above!</div>

			<div style={{display: ( showBoxN(loc_parts[3]) && props.loadingPapers ) ? 'block' : 'none' }}>
				Loading past papers...
			</div>

			<div style={{display: ( showBoxN(loc_parts[3]) && !props.loadingPapers ) ? 'block' : 'none' }}>

				{papersFiltered
					?
					<> 
					{/* Papers ARE Filtered (e.g. 1-H) */}
					<div className="papertype-box">
					{props.paperData.papers.filter(item => item.id === 'papers/uk/' + loc_parts[1] + '/' + loc_parts[2] + '/' + loc_parts[3]).map(item =>
							<>
							{item.pprtypes.filter(item => item.pprtype.replace('-','') === paperTypeUrl).map(item =>
								<>
									{item['yr-months'].map(item =>
									<>
										<div className="paper-pill-2-around">
											<div key={item.id} className="paper-pill-2"> 
											</div>
										</div>

										<div className="papers-slide-parent">
												{item.papers.map((it, index) =>
													<div key={index} className="ppr"> 
														{/* // Pre-txt papers:
														<div>
														<a href={"https://examoo.s3.eu-west-2.amazonaws.com/"+item.id+it} target="_blank">
																<div><span>{paperDict[it]}</span></div>
																<div></div>
																<div></div> 
														</a>
														</div>
														*/}

														<div>
														<a href={it['url']} target="_blank">
																<div><img style={{width: '90%', marginTop: '4px'}} src={`${process.env.PUBLIC_URL}/img/`+it['name']+'.svg'} alt={qualName+' '+subjName+' '+examBoardName+' '+paperTypeName+' '+paperDict[it['name']]+' '+item.yr+' '+item.month} /></div> {/* {paperDict[it['name']]} */}
																<div></div>
																<div></div> 
														</a>
														</div>

													</div>
												)}
										</div>


										<div style={{background: '#00000080', width: '90%', margin: '0px auto 20px auto', color: 'white', height: '45px', position: 'relative', borderRadius: '0px 0px 20px 20px'}}>
											
											<span>
												<span style={{position: 'absolute', left: '15px'}}>
													<span>{item.yr}</span> 
													<span className="month-style">{item.month.toUpperCase()}</span> 
												</span>

												<span style={{position: 'absolute', right: '15px'}}>
													<span>
														
														<img style={{height: '30px', opacity: '0.3', transform: 'translate(16px, -6.5px)'}} src={vertical} alt="Small vertical line"/> 
													</span>
													
													<button onClick={ Cookies.get('rcl_consent_given') ? () => { props.usePersStatesPaperYear[item.id][1]( !props.usePersStatesPaperYear[item.id][0] ) } : undefined} role="checkbox" aria-checked={props.usePersStatesPaperYear[item.id][0]} aria-labelledby="chk-tick-label">
														
														{props.usePersStatesPaperYear[item.id][0]
														?
														<svg className="tick-onoff" style={{background: '#63D771'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
														viewBox="0 0 75.8 56.9" xmlSpace="preserve">
															<rect x="12.6" y="15.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.0908 25.0116)" fill="#FFFFFF" width="15.2" height="41.8"/>
															<rect x="39.8" y="-4.2" transform="matrix(0.7071 0.7071 -0.7071 0.7071 33.986 -25.1457)" fill="#FFFFFF" width="15.2" height="65.3"/>
														</svg>

														:
														<svg className="tick-onoff" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
														viewBox="0 0 75.8 56.9" xmlSpace="preserve">
															<rect x="12.6" y="15.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.0908 25.0116)" fill="#FFFFFF" width="15.2" height="41.8"/>
															<rect x="39.8" y="-4.2" transform="matrix(0.7071 0.7071 -0.7071 0.7071 33.986 -25.1457)" fill="#FFFFFF" width="15.2" height="65.3"/>
														</svg>

														}

													</button>
													<label id="chk-tick-label" style={{display: 'none'}}>Toggle to save whether or not you have completed the papers from this year.</label>
													
												</span>
											</span>
										</div>
									</>
									)}
								</>
								)}
							</>
							)}
					</div>
					</>

					: 
					<>
					{/* Papers NOT Filtered (i.e. all papers here) */}

					{paperTypeDataFilter.map(item0 =>
						<div className="papertype-box" style={{paddingBottom: '1px'}}>
						<div className="qual-title-papers">{item0.name}</div>

						{props.paperData.papers.filter(item => item.id === 'papers/uk/' + loc_parts[1] + '/' + loc_parts[2] + '/' + loc_parts[3]).map(item =>
							<>
							{item.pprtypes.filter(item => item.pprtype === item0.url).map(item =>
								<>
									{item['yr-months'].map(item =>
									<>
										<div className="paper-pill-2-around">
											<div key={item.id} className="paper-pill-2"> 
											</div>
										</div>
										
										
										<div className="papers-slide-parent">
												
													
												{item.papers.map((it, index) =>
													<div key={index} className="ppr"> 
														<div>
														<a href={it['url']} target="_blank">
																<div><img style={{width: '90%', marginTop: '4px'}} src={`${process.env.PUBLIC_URL}/img/`+it['name']+'.svg'} alt={qualName+' '+subjName+' '+examBoardName+' '+paperTypeName+' '+paperDict[it['name']]+' '+item.yr+' '+item.month} /></div> {/* {paperDict[it['name']]} */}
																<div></div>
																<div></div> 
														</a>
														</div>
													</div>
												)}
										</div>
										
			
			
										<div style={{background: '#00000080', width: '90%', margin: '0px auto 20px auto', color: 'white', height: '45px', position: 'relative', borderRadius: '0px 0px 20px 20px'}}>
											
											<span>
												<span style={{position: 'absolute', left: '15px'}}>
													<span>{item.yr}</span> 
													<span className="month-style">{item.month.toUpperCase()}</span> 
												</span>
			
												<span style={{position: 'absolute', right: '15px'}}>
													<span>
														
														<img style={{height: '30px', opacity: '0.3', transform: 'translate(16px, -6.5px)'}} src={vertical} alt="Small vertical line"/> 
													</span>
			
													
													<button onClick={ Cookies.get('rcl_consent_given') ?  () => { props.usePersStatesPaperYear[item.id][1]( !props.usePersStatesPaperYear[item.id][0] ) } : undefined} role="checkbox" aria-checked={props.usePersStatesPaperYear[item.id][0]} aria-labelledby="chk-tick-label-2">
														
														{props.usePersStatesPaperYear[item.id][0]
														?
														<svg className="tick-onoff" style={{background: '#63D771'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
														viewBox="0 0 75.8 56.9" xmlSpace="preserve">
															<rect x="12.6" y="15.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.0908 25.0116)" fill="#FFFFFF" width="15.2" height="41.8"/>
															<rect x="39.8" y="-4.2" transform="matrix(0.7071 0.7071 -0.7071 0.7071 33.986 -25.1457)" fill="#FFFFFF" width="15.2" height="65.3"/>
														</svg>
			
														:
														<svg className="tick-onoff" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
														viewBox="0 0 75.8 56.9" xmlSpace="preserve">
															<rect x="12.6" y="15.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.0908 25.0116)" fill="#FFFFFF" width="15.2" height="41.8"/>
															<rect x="39.8" y="-4.2" transform="matrix(0.7071 0.7071 -0.7071 0.7071 33.986 -25.1457)" fill="#FFFFFF" width="15.2" height="65.3"/>
														</svg>
			
														}
			
													</button>
													<label id="chk-tick-label-2" style={{display: 'none'}}>Toggle to save whether or not you have completed the papers from this year.</label>
													
													
												</span>
											</span>
										</div>

										{/* <Adsense
										client="ca-pub-3775908651962462"
										data-adtest="on"
										slot="6258212978"
										style={{ display: 'block' }}
										layout="in-article"
										format="fluid"
										/> */}
									</>
								)}
								</>
							)}
							</>
						)}	

					</div>

					)}
					



					{/* Papers NOT Filtered (i.e. all papers here) */}
					{/*
					{paperTypeDataFilter.map(item0 =>
						<div className="papertype-box" style={{paddingBottom: '1px'}}>
						<div className="qual-title-papers">{item0.name}</div>

						{props.paperData.papers.filter(item => item.papertype === item0.url).map(item =>
							<>
							<div className="paper-pill-2-around">
								<div key={item.id} className="paper-pill-2"> 
								</div>
							</div>

							<div className="papers-slide-parent">
									
										
									{item.papers.map((it, index) =>
										<div key={index} className="ppr"> 
											<div>
											<a href={"https://examoo.s3.eu-west-2.amazonaws.com/"+item.url+it} target="_blank">
													<div><span>{paperDict[it]}</span></div>
													<div></div>
													<div></div> 
											</a>
											</div>
										</div>
									)}
							</div>


							<div style={{background: '#00000080', width: '90%', margin: '0px auto 20px auto', color: 'white', height: '45px', position: 'relative', borderRadius: '0px 0px 20px 20px'}}>
								
								<span>
									<span style={{position: 'absolute', left: '15px'}}>
										<span>{item.year}</span> 
										<span className="month-style">{item.month.toUpperCase()}</span> 
									</span>

									<span style={{position: 'absolute', right: '15px'}}>
										<span>
											
											<img style={{height: '30px', opacity: '0.3', transform: 'translate(16px, -6.5px)'}} src={vertical} alt=""/> 
										</span>

										
										<span onClick={() => { props.usePersStatesPaperYear[item.url][1]( !props.usePersStatesPaperYear[item.url][0] ) }}>
											
											{props.usePersStatesPaperYear[item.url][0]
											?
											<svg className="tick-onoff" style={{background: '#63D771'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
											viewBox="0 0 75.8 56.9" xmlSpace="preserve">
												<rect x="12.6" y="15.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.0908 25.0116)" fill="#FFFFFF" width="15.2" height="41.8"/>
												<rect x="39.8" y="-4.2" transform="matrix(0.7071 0.7071 -0.7071 0.7071 33.986 -25.1457)" fill="#FFFFFF" width="15.2" height="65.3"/>
											</svg>

											:
											<svg className="tick-onoff" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
											viewBox="0 0 75.8 56.9" xmlSpace="preserve">
												<rect x="12.6" y="15.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.0908 25.0116)" fill="#FFFFFF" width="15.2" height="41.8"/>
												<rect x="39.8" y="-4.2" transform="matrix(0.7071 0.7071 -0.7071 0.7071 33.986 -25.1457)" fill="#FFFFFF" width="15.2" height="65.3"/>
											</svg>

											}

										</span>
									</span>
								</span>
							</div>
							</>
						)}	

					</div>

					)}
					*/}
					</>
				}


				
				{/* <div><p>Back to top</p></div> */}

				<div style={{paddingBottom: '5px', marginTop: '-2px'}}>
					<p style={{width: '250px', margin: '0 auto 20px auto', fontSize: '15px', lineHeight: '17px', opacity: 0.3, cursor: 'default'}}>That's all the {examBoardName+' '+subjName+' '+qualName} past papers!</p>
				</div> 


			</div>


			{paperTypeEnabled
			?
			<span style={{opacity: '0.4', margin: '-11px auto 0 auto', paddingBottom: '10px', display: 'table'}}>
				<a href="https://discord.gg/tc9TNjQvUp" target="_blank" style={{margin: '0 3px'}}> <img src={socialDiscord} alt="Discord Logo" style={{width: '34px', padding: '0px 3px', transform: 'translateY(-1.5px)'}} /></a>
				<a href="https://www.instagram.com/examoo.co.uk/" target="_blank" style={{margin: '0 4px'}}> <img src={socialInsta} alt="Instagram Logo" style={{width: '30px', padding: '0px 3px'}} /></a>
				<a href="https://www.tiktok.com/@examoo.co.uk" target="_blank" style={{margin: '0 4px'}}> <img src={socialTiktok} alt="Tiktok Logo" style={{width: '30px', padding: '0px 3px'}} /></a>
			</span>
			:
			<>
			</>
			}

		</div>

		<div>
			{/* A Route is like urls.py in Django, but for SPA (single-page apps) 
				The 'thing' in <BoardPaper thing={thing} /> goes into 'props' of next file. 
				It's like 'context' in Django, but works for functions awa variables. */}
			{/*
			<Routes> 
				<Route path="/ruff/*" element={<QualSubject 
					pathNavBar={props.pathNavBar}
					setPathNavBar={props.setPathNavBar}/>
				}/>
			</Routes>
			*/}
		</div>

		</div>

		<div className="sidebar sidebar-right">
			{paperTypeEnabled
			?
			<div className="fade-in">
			<p style={{transform: 'translateX(-11px)'}}>have to pay rent sorry <span style={{fontSize: '20px', position: 'absolute', transform: 'translate(5px, -2px)'}}>😬</span></p>
			<img src={meat} alt="Meat crisps" />

			<Adsense
				client="ca-pub-3775908651962462"
				data-adtest="on"
				slot="4362391969"
				style={{ display: 'block' }}
				data-ad-format="auto"
				data-full-width-responsive="true"
			/>
			</div>
			:
			<>
			</>
			}
		</div>



		</div>
		</>


	);

	
}



export default Exams;




 
// ----- BUGS -----

// refreshing on anything but http://localhost:3000 gives error !!! (even if just a '/' afterwards)
// [ Uncaught TypeError: Cannot read properties of null (reading 'filter') ]

//