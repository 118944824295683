import React, {useEffect} from "react";
import createPersistedState from 'use-persisted-state';

import Exams from "./Exams";
//import logo from "./logo.png";



function Home(props) {


	const location = window.location.pathname; // gets current url
	//console.log('loc: ' + location);
	var loc_path = location; // '/gcse/maths/aqa'
	var loc_parts = loc_path.split('/'); // ['', 'gcse', 'maths', 'aqa']

  /*

	useEffect(() => {
	}, [ loc_parts[3] ]);

  */



  /*
  */

  /*
  var listK = [];

  for(var grp2 in props.paperData.papers) {
    listK.push(String(props.paperData.papers[grp2].url));
  };

  console.log('listK: '+listK);

  for (var i = 0; i < listK.length; i++) {
    //console.log(i);
    //console.log(listK[i]);
    usePersStatesPaperYear[listK[i]] = createPersistedState(listK[i])(false);
  };

  console.log('usePersStatesPaperYear');
  console.log(usePersStatesPaperYear);
  */
 

  /* 
  creates object: 
  usePersStatesPaperYear = {
    'long_id_1': [state, setState()],
    'long_id_2': [state2, setState2()],
    'long_id_3': [state3, setState3()],
    etc.
  }
  */



  return (
    <Exams
    pathNavBar={props.pathNavBar}
    setPathNavBar={props.setPathNavBar}

    qualData={props.qualData} 
    setQualData={props.setQualData}
    colourData={props.colourData} 
    setColourData={props.setColourData}
    subjData={props.subjData} 
    setSubjData={props.setSubjData}
    examBoardData={props.examBoardData} 
    setExamBoardData={props.setExamBoardData}
    paperTypeData={props.paperTypeData} 
    setPaperTypeData={props.setPaperTypeData}
    testData={props.testData} 
    setTestData={props.setTestData}
    paperData={props.paperData} 
    //setPaperData={props.setPaperData}

    usePersStatesPaperType={props.usePersStatesPaperType}
    usePersStatesPaperYear={props.usePersStatesPaperYear}
    />
  );
}
 
export default Home;