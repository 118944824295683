import React, { useEffect } from "react";


function Terms() {
  
  useEffect(() => {
    document.title = "Terms • Examoo";
  }, []);

  return (
    <>
    <h1 style={{textAlign: 'center', background: '#0000bb', padding: '10px 0', color: 'white'}}>Terms of Service</h1>

    <div>
      {/* <p style={{margin: '10px', textAlign: 'center'}}><i>A summary of this document, written for young people, is available <a href>here</a>.</i></p>  */}
      <p style={{textAlign: 'center'}}>Last updated: 18th May 2022</p>
      <div style={{textAlign: 'left', marginTop: '0px', marginBottom: '30px', padding: '0px 20px 10px 20px', overflowWrap: 'break-word'}}>
        <p className="legal-title">
          1. Introduction
        </p><p>
          Welcome to <b>Examoo.co.uk</b> (“<b>Company</b>”, “<b>we</b>”, “<b>our</b>”, “<b>us</b>”)! As you have just clicked our Terms of Service, please pause, grab a cup of hot chocolate and carefully read the following pages. It will take you approximately 20 minutes.
        </p><p>
          These Terms of Service (“<b>Terms</b>”, “<b>Terms of Service</b>”) govern your use of our web pages located at <a href="https://www.examoo.co.uk">https://www.examoo.co.uk</a> and our mobile application Examoo (together or individually “<b>Service</b>”) operated by Examoo.co.uk.
        </p><p>
          Our <a href="../privacy">Privacy Policy</a> and <a href="../cookies">Cookie Policy</a> also govern your use of our Service and explain how we collect, safeguard and disclose information that results from your use of our web pages. The Privacy Policy and Cookie Policy can be read at <a href="../privacy">https://www.examoo.co.uk/privacy</a> and <a href="../cookies">https://www.examoo.co.uk/cookies</a>.
        </p><p>
          Your agreement with us includes these Terms, the Privacy Policy and Cookie Policy (“Agreements”). You acknowledge that you have read and understood Agreements, and agree to be bound of them.
        </p><p>
          If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but please let us know by emailing at ExamooContact@gmail.com so we can try to find a solution. These Terms apply to all visitors, users and others who wish to access or use Service.
        </p><p>
          Thank you for being responsible.
        </p>
        <p className="legal-title"> 
          2.	Content
        </p><p>
          Content found on or through this Service are the property of Examoo.co.uk or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.
        </p><p>
          Although we try to update and revise the Content on our Service, we cannot make any guarantee that the information is accurate, comprehensive, or up-to-date.
        </p><p>
          Examoo.co.uk not liable for any inaccuracies included in the Content on our Service. We are not liable for any offence or upset caused by the Content on our Service. We are not liable for exam results, future careers, or future career earnings as a result of the use of the Content on our Service.
        </p><p>
          When you submit content to us (such as via the website, Discord server, or email), you simultaneously grant Examoo.co.uk an irrevocable, worldwide, royalty free license to publish, display, modify, distribute and syndicate your content worldwide. You confirm and warrant that you have the required authority to grant the above license to Examoo.co.uk.
        </p><p>
          The content of the pages of our Service is for your general information and use only. It is subject to change without notice.
        </p>
        <p className="legal-title">
          3.	Prohibited Uses
        </p><p>
          You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:
        </p><p>
          (a)	In any way that violates any applicable national or international law or regulation.
        </p><p>
          (b)	For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.
        </p><p>
          (c)	To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.
        </p><p>
          (d)	To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.
        </p><p>
          (e)	In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
        </p><p>
          (f)	To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.
        </p><p>
          Additionally, you agree not to:
        </p><p>
          (a)	Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of Service, including their ability to engage in real time activities through Service.
        </p><p>
          (b)	Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.
        </p><p>
          (c)	Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.
        </p><p>
          (d)	Use any device, software, or routine that interferes with the proper working of Service.
        </p><p>
          (e)	Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.
        </p><p>
          (f)	Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.
        </p><p>
          (g)	Attack Service via a denial-of-service attack or a distributed denial-of-service attack.
        </p><p>
          (h)	Take any action that may damage or falsify Company rating.
        </p><p>
          (i)	Otherwise attempt to interfere with the proper working of Service.
        </p>
        <p className="legal-title">
          4.	Analytics
        </p><p>
          We may use third-party Service Providers to monitor and analyze the use of our Service.
        </p><p>
          <b>Google Analytics</b> <br />
          Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.
        </p><p>
          For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>
        </p><p>
          We also encourage you to review the Google's policy for safeguarding your data: <a href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a>.
        </p>
        {/* <p>
          <b>Cloudflare analytics</b> <br />
          Cloudflare analytics is a web analytics service operated by Cloudflare Inc. Read the Privacy Policy here: <a href="https://www.cloudflare.com/privacypolicy/">https://www.cloudflare.com/privacypolicy/</a>
        </p> */}
        <p className="legal-title">
          5.	Contests, Sweepstakes and Promotions
        </p><p>
          Any contests, sweepstakes or other promotions (collectively, “<b>Promotions</b>”) made available through Service may be governed by rules that are separate from these Terms of Service. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms of Service, Promotion rules will apply.
        </p>
        <p className="legal-title">
          6.	Intellectual Property
        </p><p>
          Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Examoo.co.uk and its licensors. Service is protected by copyright, trademark, and other laws of the United Kingdom and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Examoo.co.uk.
        </p><p>
          Any redistribution or reproduction of any of our past paper Content in any form is prohibited, other than printing or downloading to a local storage drive for your personal and non-commercial use only.
        </p><p>
          You may not distribute or commercially exploit the past paper Content, except without our written consent. You may not transmit past paper Content, or store it on any other website or form of electronic retrieval system.
        </p><p>
          Service contains Content that is either owned by us, licenced to us or submitted by users. Please contact us if you become aware of any material that infringes your copyright on the Service.
        </p>
        {/*
        <p>
          Permission for distribution for educational use would normally be granted to educational institutes as long as the licence of the material in question allows this. Please contact us for further information.
        </p> */}
        <p className="legal-title">
          7.	Copyright Policy
        </p><p>
          We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on Service infringes on the copyright or other intellectual property rights (“<b>Infringement</b>”) of any person or entity.
        </p><p>
          If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to ExamooContact@gmail.com, with the subject line: “Copyright Infringement” and include in your claim a detailed description of the alleged Infringement as detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims”
        </p><p>
          You may be held accountable for damages (including costs and attorneys' fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through Service on your copyright.
        </p>
        <p className="legal-title">
          8.	DMCA Notice and Procedure for Copyright Infringement Claims
        </p><p>
          You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):
        </p><p>
          (a)	an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest;
        </p><p>
          (b)	a description of the copyrighted work that you claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work;
        </p><p>
          (c)	identification of the URL or other specific location on Service where the material that you claim is infringing is located;
        </p><p>
          (d)	your address, telephone number, and email address;
        </p><p>
          (e)	a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;
        </p><p>
          (f)	a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.
        </p><p>
          You can contact our Copyright Agent via email at ExamooContact@gmail.com
        </p>
        <p className="legal-title">
          9.	Error Reporting and Feedback
        </p><p>
          You may provide us directly at ExamooContact@gmail.com with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“<b>Feedback</b>”). You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii) Company may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from you or any third party; and (iv) Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.
        </p>
        <p className="legal-title">
          10.	Links To Other Web Sites
        </p><p>
          Our Service may contain links to third party web sites or services that are not owned or controlled by Examoo.co.uk.
        </p><p>
          Examoo.co.uk has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.
        </p><p>
          YOU ACKNOWLEDGE AND AGREE THAT EXAMOO.CO.UK SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.
        </p><p>
          WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
        </p>
        <p className="legal-title">
          11.	Disclaimer Of Warranty 
        </p><p>
          THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
        </p><p>
          NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
        </p><p>
          COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
        </p><p>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <p className="legal-title">
          12.	Limitation Of Liability
        </p><p>
          EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
        </p>
        <p className="legal-title">
          13.	Governing Law
        </p><p>
          These Terms shall be governed and construed in accordance with the laws of the United Kingdom without regard to its conflict of law provisions.
        </p><p>
          Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding Service.
        </p><p>
          If you are a consumer, please note that these terms of use, their subject matter and their formation, are governed by English law. You and we both agree to that the courts of England and Wales will have exclusive jurisdiction except that if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may also bring proceedings in Scotland. If you are a business, these terms of use, their subject matter and their formation (and any non-contractual disputes or claims) are governed by English law. We both agree to the exclusive jurisdiction of the courts of England and Wales.
        </p>
        <p className="legal-title">
          14.	Changes To Service
        </p><p>
          We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.
        </p>
        <p className="legal-title">
          15.	Amendments To Terms
        </p><p>
          We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.
        </p><p>
          Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.
        </p><p>
          By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Service.
        </p>
        <p className="legal-title">
          16.	Waiver And Severability
        </p><p>
          No waiver by Company of any term or condition set forth in Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Company to assert a right or provision under Terms shall not constitute a waiver of such right or provision.
        </p><p>
          If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms will continue in full force and effect.
        </p>
        <p className="legal-title">
          17.	Acknowledgement
        </p><p>
          BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
        </p>
        <p className="legal-title">
          18.	Contact Us
        </p><p>
          Please email your feedback, comments or requests for technical support to ExamooContact@gmail.com. 
        </p>
        <br/><br/>
      </div>
    </div>
    </>
  );
}
 
export default Terms;