import React from "react";
import ReactDOM from "react-dom";
import Main from "./Main";

import logo from "./logo.png"; // for 'coming soon' page
import "./examoo.css";

//import { CookiesProvider } from "react-cookie";
 
ReactDOM.render(
  
  <> 
    <Main/>
  </>, 
  
  document.getElementById("root")
);