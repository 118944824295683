import React, { useEffect } from "react";

import mockup2 from "./mockup2.png";
import mockup2webp from "./mockup2webp.webp";

import mockupBmk2 from "./mockup-bmk-2.png";
import mockupBmk2webp from "./mockup-bmk-2.webp";

import mockupPF from "./mockup-pf.png";
import mockupPFwebp from "./mockup-pf.webp";

import mockupPFqs from "./mockup-pf-qs.png";
import mockupPFqswebp from "./mockup-pf-qs.webp";

import vertical from "./vertical-line.svg";
import mail from "./examoo-mail.svg";
import fox from "./custom-fox-2.gif";
import mysteryGift from "./mystery-gift.svg";

import socialDiscord from "./social-discord.svg";
import socialInsta from "./social-insta.svg";
import socialTiktok from "./social-tiktok.svg";

import {
  Routes,
  Route,
  NavLink,
} from "react-router-dom";

import Terms from "./Terms";
import Privacy from "./Privacy";

/*
const observer = new IntersectionObserver((entries) => { 
  entries.forEach((entry) => {
      console.log(entry.target);
      //constellationAnimationHomeDefault();
  })
}); 
*/


//document.addEventListener("DOMContentLoaded", function() {
window.addEventListener('load', function () {
  //console.log('RUFFFFF');
  constellationAnimationHomeDefault();

  //observer.observe(document.getElementById('root3'));
});



function constellationAnimationHomeDefault() {

  var line_list, k, style, star, style2;

  const lines_all = [0,1,1,1,1,1,1,1,1,1,1];


  var delay_mod = 1;
  var slomo_mode = false;

  if (slomo_mode === true) {
      delay_mod = 5; // animations 5 times slower
  }

  var const_star_list = document.querySelectorAll("#root3 polygon.star");

  line_list = document.querySelectorAll("#root3 line"); // for animating the <line> in constellation .svg (this is below on this page)

  var line_count_prev_reset = 0;
  var line_count_reset = 0; // counts no. of lines that have been used so far


  // reset (if animation already done)
  for (var s=0; s<const_star_list.length; s++) { 
      if (10 > s) { // animate up to no. of collected stars
          const_star_list[s].setAttribute("style", ""); 

          line_count_prev_reset = line_count_reset;
          line_count_reset += lines_all[s];

          for (var l=line_count_prev_reset; l<line_count_reset; l++) { 
              line_list[l].setAttribute("style", ""); // have no style for current line
          };
      };
  };

  function ActualAnimation() {

      var line_count_prev = 0;
      var line_count = 0; // counts no. of lines that have been used so far
      
      for (s=0; s<const_star_list.length; s++) { 
          if (10 > s) { // animate up to no. of collected stars
              style2 = "animation: star-grow-2 "+5*delay_mod+"00ms forwards; animation-delay: "+s*delay_mod+"00ms;"; // star wiggle attempt: 'jason-derulo 1s infinite, star-grow-2 500ms forwards;'
              const_star_list[s].setAttribute("style", style2); 

              line_count_prev = line_count;
              line_count += lines_all[s];

              for (l=line_count_prev; l<line_count; l++) { 
                  line_list[l].setAttribute("style", ""); // have no style for current line
                  var style1 = "--L:" + line_list[l].getTotalLength() + ";"; 
                  style1 += "animation: line-grow "+5*delay_mod+"00ms forwards; animation-delay: "+l*delay_mod+"00ms; stroke-width: 7; stroke-dasharray: 0 var(--L);";
                  line_list[l].setAttribute("style", style1);
              }
          };
      };
  };
  
  //window.addEventListener("load", magic, false);
  ActualAnimation();

}


 

function About() {

  useEffect(() => {
    //window.scrollTo(0, 0); // otherwise scrolled halfway down when coming from 'All Exams'
    document.title = "About • Examoo";
    constellationAnimationHomeDefault();
  }, []);

  return (
    <>
    <div className="about-page">

      <div>
        <h2 className="" style={{marginTop: '25px'}}>Fast Papers.</h2>
        <h4 className="rounded-box" style={{marginTop: '0px'}}>Examoo gets every exam paper you need, at lightning speed <span class="shadow-emoji">⚡️</span></h4> {/*  <span class="shadow-emoji">⚡️</span> */}
        
        <div className="rocket animate__fadeInUp" style={{marginTop: '-40px'}} >
        
          {/* <img rel="preload" id="iphone-mockup" src={mockup} style={{}} alt=""/>  */}

          <picture>
            <source srcset={mockup2webp} type="image/webp" />
            <img id="iphone-mockup" src={mockup2} alt="An iPhone showing the GCSE Maths AQA page of www.examoo.co.uk." />
          </picture>

          <div className="square"></div>

          <div className="falling-papers-parent">
            <div className="falling-papers">

              <div className="papers-layer-1">

                <div className="ppr2 ppr-small" id="p1"> 
                  <div>
                    <div>
                      <span className="text">
                        Insert
                      </span>
                    </div>
                  </div>
                </div>


                <div className="ppr2 ppr-small" id="p2"> 
                  <div>
                    <div>
                      <span className="text">
                        Exam Report
                      </span>
                    </div>
                  </div>
                </div>

              </div>

              <div className="papers-layer-2">

                <div className="ppr2 ppr-small" id="p3"> 
                  <div>
                    <div>
                      <span className="text">
                        Qstn. <br/> Paper
                      </span>
                    </div>
                  </div>
                </div>


                <div className="ppr2 ppr-small" id="p4"> 
                  <div>
                    <div>
                      <span className="text">
                        Periodic <br/> Table
                      </span>
                    </div>
                  </div>
                </div>


                <div className="ppr2 ppr-small" id="p5"> 
                  <div>
                    <div>
                      <span className="text">
                        Specifi-<br/>cation
                      </span>
                    </div>
                  </div>
                </div>

              </div>


              <div className="papers-layer-3">

                <div className="ppr2 ppr-small" id="p6"> 
                  <div>
                    <div>
                      <span className="text">
                        Formula<br/>Book
                      </span>
                    </div>
                  </div>
                </div>



                <div className="ppr2 ppr-small" id="p7"> 
                  <div>
                    <div>
                      <span className="text">
                        Mark <br/> Scheme
                      </span>
                    </div>
                  </div>
                </div>



                <div className="ppr2 ppr-small" id="p8"> 
                  <div>
                    <div>
                      <span className="text">
                        Exam Report
                      </span>
                    </div>
                  </div>
                </div>


                <div className="ppr2 ppr-small" id="p9"> 
                  <div>
                    <div>
                      <span className="text">
                        Qstn. <br/> Paper
                      </span>
                    </div>
                  </div>
                </div>

              </div>


              <div className="ppr-big-group">

                <div className="ppr ppr-big" id="big-p1"> 
                  <div>
                      <div>
                        <span style={{fontSize: '12px', lineHeight: '13px', padding: '6px'}}>
                          10,000+ papers! 
                          <span className="lighter" style={{fontSize: '9px', lineHeight: '9px', width: '44px', marginTop: '15px'}}>
                          (yes it took ages)
                          </span>
                        </span>
                      </div>
                  </div>
                </div>

                <div className="ppr ppr-big" id="big-p2"> 
                  <div>
                        <div><span style={{fontSize: '12.5px', lineHeight: '13px', padding: '5px', width: '120%'}}>GCSE, IGCSE, <br/>AS, <br/>A-Level, etc.*</span></div>
                  </div>
                </div>

                <div className="ppr ppr-big" id="big-p3"> 
                  <div>
                        <div><span>100% free. <span style={{color: '#ccc'}}>No bull.</span></span></div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>


          <p className="paper-notice">*IB, BTEC and WJEC papers coming soon!</p>
      
        </div>
      </div>


      <div>
        <h2>Save your exams.</h2>


        <div className="rounded-box-containter">

          <div className="rounded-box" style={{paddingTop: '0px'}}>

          <picture>
            <source srcset={mockupBmk2webp} type="image/webp" />
            <img  id="mockup-bookmarks" src={mockupBmk2} alt="An iPhone on the Bookmarks page of examoo.co.uk, showing many bookmarked subjects (Maths, Biology, Physics and History)" />
          </picture>
          Bookmark your exams <span className="lighter">to get them even faster next time.</span></div>

          <div className="rounded-box rb-completed" style={{overflow: 'hidden'}}>
            <span style={{fontSize: '40px', lineHeight: '40px'}}>
              <span className="white-lighter">Keep track of the papers you have</span> completed.
            </span>
          
            <div style={{marginLeft: '-220px', marginTop: '20px'}}>
                <div className="paper-pill-2-around">
                    <div className="paper-pill-2" style={{left: '10px', transform: 'translate(117.5px, 30.5px)', width: '283px'}}> 
                    </div>
                </div>

                <div className="papers-slide-parent" style={{justifyContent: 'center'}}>
                  <div className="ppr"> 
                      <div>
                        <div><img style={{width: '90%', marginTop: '4px', transform: 'translateX(-3px)'}} src={`${process.env.PUBLIC_URL}/img/`+'qp.svg'} alt="Question Paper" /></div> 
                        <div></div>
                        <div></div> 
                      </div>
                  </div>
                  <div className="ppr"> 
                      <div>
                        <div><img style={{width: '90%', marginTop: '4px', transform: 'translateX(-3px)'}} src={`${process.env.PUBLIC_URL}/img/`+'ms.svg'} alt="Mark Scheme" /></div> 
                        <div></div>
                        <div></div> 
                      </div>
                  </div>
                  <div className="ppr"> 
                      <div>
                        <div><img style={{width: '90%', marginTop: '4px', transform: 'translateX(-3px)'}} src={`${process.env.PUBLIC_URL}/img/`+'rep.svg'} alt="Exam Report" /></div> 
                        <div></div>
                        <div></div> 
                      </div>
                  </div>
                </div>




                <div style={{background: '#00000080', width: '300px', margin: '0px auto 20px auto', color: 'white', height: '45px', position: 'relative', borderRadius: '0px 0px 20px 20px'}}>
                    
                    <span>
                        <span style={{position: 'absolute', left: '15px', top: '8px'}}>
                            <span>2020</span> 
                            <span className="month-style">NOVEMBER</span> 
                        </span>

                        <span style={{position: 'absolute', right: '15px'}}>
                            <span>
                                
                                <img style={{height: '30px', opacity: '0.3', transform: 'translate(16px, -6.5px)'}} src={vertical} alt="Small vertical line"/> 
                            </span>

                            
                            <span>
                                
                                <svg className="tick-onoff" style={{background: '#63D771'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 75.8 56.9" xmlSpace="preserve">
                                    <rect x="12.6" y="15.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.0908 25.0116)" fill="#FFFFFF" width="15.2" height="41.8"/>
                                    <rect x="39.8" y="-4.2" transform="matrix(0.7071 0.7071 -0.7071 0.7071 33.986 -25.1457)" fill="#FFFFFF" width="15.2" height="65.3"/>
                                </svg>

                                {/*
                                <svg className="tick-onoff" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 75.8 56.9" xmlSpace="preserve">
                                    <rect x="12.6" y="15.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.0908 25.0116)" fill="#FFFFFF" width="15.2" height="41.8"/>
                                    <rect x="39.8" y="-4.2" transform="matrix(0.7071 0.7071 -0.7071 0.7071 33.986 -25.1457)" fill="#FFFFFF" width="15.2" height="65.3"/>
                                </svg>

                                */}

                            </span>
                            

                        </span>
                    </span>
                </div>

            </div>

            <div className="completed-below-text">Your exam progress will be saved, even if you leave the site!</div>
          
          
          </div>

        </div>
      </div>

      <div>
        <h2>Something <span className="outline-text">missing</span>?</h2>

        <div className="rounded-box-containter">
          <div className="rounded-box rb-cracks" style={{fontSize: '22px'}}>
            <span style={{color: '#ffffffc0'}}>Though we try to have every exam, occasionally some papers might slip through the cracks...</span>
          
          <div className="ppr-crack-parent">

          <div className="ppr-crack">
            <div className="ppr"> 
              <div>
                <div><span>Mark Scheme</span></div>
                <div></div>
                <div></div> 
              </div>
            </div>
          </div>

          </div>

          </div>
            {/* <p style={{margin: '10px auto', textAlign: 'center'}}>*paper is thin, after all.</p> */}
          <div className="rounded-box">Let us know of any missing papers on our official discord server.
          <br/>
          <span className="lighter">There you'll also find discussions and tips from fellow exam-sufferers.</span>

          <a href="https://discord.gg/tc9TNjQvUp" target="_blank">
          <div className="button-discord">
            <span>Join our</span>

            <div id="logo-discord">
              <svg style={{transform: 'translateY(-5px)'}} viewBox="0 0 292 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                
                <g clipPath="url(#clip0)">
                <g clipPath="url(#clip1)">
                <path d="M61.7958 16.494C57.0736 14.2846 52.0244 12.6789 46.7456 11.7646C46.0973 12.9367 45.3399 14.5132 44.8177 15.7673C39.2062 14.9234 33.6463 14.9234 28.138 15.7673C27.6159 14.5132 26.8413 12.9367 26.1872 11.7646C20.9027 12.6789 15.8477 14.2905 11.1255 16.5057C1.60078 30.8988 -0.981215 44.9344 0.309785 58.7707C6.62708 63.4883 12.7493 66.3541 18.7682 68.2294C20.2543 66.1841 21.5797 64.0099 22.7215 61.7185C20.5469 60.8922 18.4641 59.8725 16.4961 58.6887C17.0182 58.3019 17.5289 57.8975 18.0223 57.4814C30.0257 63.0957 43.0677 63.0957 54.9277 57.4814C55.4269 57.8975 55.9375 58.3019 56.4539 58.6887C54.4801 59.8783 52.3916 60.898 50.217 61.7244C51.3588 64.0099 52.6785 66.19 54.1703 68.2352C60.195 66.3599 66.3229 63.4942 72.6402 58.7707C74.155 42.7309 70.0525 28.8242 61.7958 16.494ZM24.3568 50.2615C20.7535 50.2615 17.7985 46.8976 17.7985 42.8012C17.7985 38.7048 20.6904 35.3351 24.3568 35.3351C28.0233 35.3351 30.9782 38.6989 30.9151 42.8012C30.9208 46.8976 28.0233 50.2615 24.3568 50.2615ZM48.5932 50.2615C44.9899 50.2615 42.0349 46.8976 42.0349 42.8012C42.0349 38.7048 44.9267 35.3351 48.5932 35.3351C52.2596 35.3351 55.2146 38.6989 55.1515 42.8012C55.1515 46.8976 52.2596 50.2615 48.5932 50.2615Z" fill="white"/>
                </g>
                </g>
                <defs>
                <clipPath id="clip0">
                <rect width="292" height="56.4706" fill="white" transform="translate(0 11.7646)"/>
                </clipPath>
                <clipPath id="clip1">
                <rect width="292" height="56.4706" fill="white" transform="translate(0 11.7646)"/>
                </clipPath>
                </defs>
              
              </svg>
              <svg style={{transform: 'translateY(-52px)'}} viewBox="0 0 292 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                <g clipPath="url(#clip1)">
                
                <path d="M98.0293 26.1707H113.693C117.469 26.1707 120.659 26.7743 123.276 27.9757C125.886 29.177 127.843 30.8531 129.14 32.998C130.436 35.1429 131.09 37.5984 131.09 40.3645C131.09 43.072 130.413 45.5275 129.059 47.7251C127.705 49.9286 125.645 51.6692 122.874 52.9526C120.103 54.236 116.671 54.8806 112.569 54.8806H98.0293V26.1707ZM112.408 47.5845C114.95 47.5845 116.907 46.934 118.272 45.6388C119.638 44.3378 120.321 42.568 120.321 40.3235C120.321 38.243 119.712 36.5845 118.496 35.3421C117.28 34.0997 115.438 33.4727 112.976 33.4727H108.076V47.5845H112.408Z" fill="white"/>
                <path d="M154.541 54.8456C152.372 54.2713 150.415 53.4391 148.677 52.3432V45.5335C149.991 46.5707 151.752 47.4264 153.961 48.1003C156.17 48.7684 158.305 49.1024 160.37 49.1024C161.334 49.1024 162.063 48.9735 162.556 48.7156C163.05 48.4578 163.297 48.1472 163.297 47.7897C163.297 47.3795 163.165 47.0396 162.895 46.7641C162.625 46.4887 162.103 46.2601 161.329 46.0667L156.509 44.9591C153.749 44.3028 151.792 43.3944 150.628 42.2282C149.463 41.0678 148.883 39.5441 148.883 37.6571C148.883 36.0689 149.388 34.6918 150.41 33.5138C151.425 32.3359 152.871 31.4275 154.747 30.7887C156.624 30.1441 158.815 29.8218 161.334 29.8218C163.583 29.8218 165.643 30.0679 167.52 30.5602C169.396 31.0525 170.945 31.6795 172.179 32.4472V38.8878C170.916 38.1201 169.47 37.5165 167.818 37.0593C166.171 36.6081 164.479 36.3854 162.734 36.3854C160.215 36.3854 158.959 36.8249 158.959 37.6981C158.959 38.1084 159.154 38.4131 159.544 38.6182C159.934 38.8233 160.651 39.0343 161.69 39.257L165.706 39.9954C168.329 40.4584 170.285 41.273 171.57 42.4333C172.856 43.5937 173.498 45.3108 173.498 47.5846C173.498 50.0752 172.437 52.0502 170.308 53.5153C168.179 54.9804 165.161 55.7129 161.248 55.7129C158.947 55.7071 156.71 55.4199 154.541 54.8456Z" fill="white"/>
                <path d="M182.978 53.9839C180.678 52.8352 178.939 51.2764 177.78 49.3073C176.621 47.3382 176.036 45.123 176.036 42.6616C176.036 40.2003 176.638 37.9968 177.843 36.057C179.048 34.1172 180.815 32.5935 183.145 31.4859C185.474 30.3783 188.257 29.8274 191.499 29.8274C195.515 29.8274 198.849 30.6889 201.5 32.4118V39.919C200.565 39.2626 199.474 38.7293 198.229 38.3191C196.984 37.9089 195.653 37.7037 194.23 37.7037C191.74 37.7037 189.795 38.1667 188.389 39.0985C186.983 40.0303 186.278 41.2434 186.278 42.7495C186.278 44.2263 186.96 45.4336 188.326 46.383C189.692 47.3265 191.671 47.8012 194.27 47.8012C195.607 47.8012 196.927 47.6019 198.229 47.2093C199.526 46.8108 200.645 46.3244 201.58 45.75V53.011C198.637 54.816 195.223 55.7185 191.338 55.7185C188.068 55.7068 185.279 55.1325 182.978 53.9839Z" fill="white"/>
                <path d="M211.518 53.9841C209.2 52.8355 207.433 51.2649 206.216 49.2665C205 47.2681 204.386 45.0412 204.386 42.5798C204.386 40.1185 204.994 37.9208 206.216 35.9928C207.438 34.0647 209.194 32.5527 211.501 31.4568C213.801 30.3609 216.55 29.8159 219.734 29.8159C222.919 29.8159 225.667 30.3609 227.968 31.4568C230.269 32.5527 232.025 34.053 233.23 35.9693C234.435 37.8857 235.037 40.0833 235.037 42.574C235.037 45.0353 234.435 47.2623 233.23 49.2606C232.025 51.259 230.263 52.8296 227.945 53.9782C225.627 55.1269 222.89 55.7012 219.729 55.7012C216.567 55.7012 213.83 55.1327 211.518 53.9841ZM223.722 46.7055C224.698 45.7093 225.191 44.3907 225.191 42.7498C225.191 41.1089 224.703 39.802 223.722 38.835C222.747 37.8622 221.415 37.3758 219.729 37.3758C218.013 37.3758 216.67 37.8622 215.689 38.835C214.714 39.8079 214.226 41.1089 214.226 42.7498C214.226 44.3907 214.714 45.7093 215.689 46.7055C216.665 47.7018 218.013 48.2058 219.729 48.2058C221.415 48.1999 222.747 47.7018 223.722 46.7055Z" fill="white"/>
                <path d="M259.17 31.3395V40.2004C258.149 39.5147 256.829 39.1748 255.194 39.1748C253.053 39.1748 251.401 39.8371 250.253 41.1615C249.1 42.486 248.526 44.5488 248.526 47.3383V54.8865H238.686V30.8883H248.326V38.5185C248.859 35.7289 249.726 33.672 250.919 32.3416C252.107 31.0172 253.644 30.355 255.515 30.355C256.932 30.355 258.149 30.6832 259.17 31.3395Z" fill="white"/>
                <path d="M291.864 25.3503V54.8866H282.023V49.5127C281.191 51.5345 279.929 53.0758 278.231 54.1306C276.532 55.1797 274.432 55.7071 271.942 55.7071C269.716 55.7071 267.777 55.1562 266.118 54.0486C264.46 52.941 263.181 51.4232 262.28 49.4951C261.385 47.567 260.931 45.387 260.931 42.9491C260.903 40.435 261.379 38.1787 262.36 36.1803C263.336 34.1819 264.718 32.6231 266.497 31.5037C268.276 30.3844 270.307 29.8218 272.585 29.8218C277.273 29.8218 280.417 31.9022 282.023 36.0572V25.3503H291.864ZM280.555 46.5415C281.559 45.5452 282.058 44.2501 282.058 42.6678C282.058 41.1382 281.57 39.8899 280.595 38.9347C279.619 37.9795 278.282 37.4989 276.601 37.4989C274.943 37.4989 273.618 37.9853 272.625 38.9581C271.632 39.931 271.139 41.1909 271.139 42.7498C271.139 44.3087 271.632 45.5804 272.625 46.5649C273.618 47.5494 274.926 48.0417 276.561 48.0417C278.219 48.0359 279.55 47.5377 280.555 46.5415Z" fill="white"/>
                <path d="M139.382 33.4432C142.091 33.4432 144.288 31.4281 144.288 28.9424C144.288 26.4567 142.091 24.4417 139.382 24.4417C136.672 24.4417 134.476 26.4567 134.476 28.9424C134.476 31.4281 136.672 33.4432 139.382 33.4432Z" fill="white"/>
                <path d="M134.472 36.5435C137.478 37.8679 141.208 37.9265 144.283 36.5435V55.0154H134.472V36.5435Z" fill="white"/>
                </g>
                </g>
                <defs>
                <clipPath id="clip0">
                <rect width="292" height="56.4706" fill="white" transform="translate(0 11.7646)"/>
                </clipPath>
                <clipPath id="clip1">
                <rect width="292" height="56.4706" fill="white" transform="translate(0 11.7646)"/>
                </clipPath>
                </defs>
              </svg>

            </div>
          </div>
          </a>

          </div> {/* <span className="shadow-emoji">✉️</span> */}

          <div className="rounded-box">
        You can also get in touch through email (ExamooContact<br/>@gmail.com).
        <br/>
          <span className="lighter">We'll update the site with your papers as soon as we can.</span>


        <a href="mailto:examoocontact@gmail.com" target="_blank">
          <div className="button-email">
            <span style={{display: 'block', transform: 'translate(13px, 20px)'}} >
              <img src={mail} style={{width: '50px'}} alt="Email icon"/> 
              <span style={{display: 'inline-block', transform: 'translate(10px, -5px)'}}>Email us</span>
            </span>
          </div>
          </a>
          </div>

        </div>
      </div>

      <div>
        <h2>Animal magnetism.</h2>


        <div className="rounded-box-containter">
          <div className="rounded-box" style={{paddingBottom: '0px'}}>

          
            
          <div style={{margin: '0px auto', width: '235px'}}>

              {/* <img rel="preload" style={{width: '85px', margin: '20px 0 0 0'}} src={logo_svg} alt="Examoo Logo"/> */}
              <svg style={{width: '85px', margin: '0px 20px 0 0', transform: 'translateX(3px) rotate(-10deg)'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 1.9 82.5 63.6" xmlSpace="preserve">
                <path fill="#404041" d="M75.3,21l0.3,33.3c0,6.2-5.7,11.2-12.6,11.2H27.5c-7,0-12.6-5-12.6-11.2V23.4c0-6.2,5.7-11.2,12.6-11.2h35.5
                  c2.6,0,12.4-1.4,12.4,7.7"/>
                <path fill="#FFAEEC" d="M59.8,62.9H24.3c-7,0-13.8-5.3-13.8-11.4V20.5c0-6.2,6.9-10.9,13.8-10.9h35.5c2.6,0,22.7-0.7,22.7,7.1
                  c-0.4,6.8-5.2,7.3-8.6,5c0,0-2-1.4-1.8,3.6l0.3,26.4C72.4,57.9,66.8,62.9,59.8,62.9z"/>
                <path fill="#FFAEEC" d="M70.9,30.9v-8.6c0-7-5.7-12.6-12.6-12.6H22.8c-2.6,0-22.7-0.8-22.7,8c0.5,6.6,6.9,6,10.3,3.4
                  c0,0,0.3,0.7,0.1,6.3l0,4.2L70.9,30.9z"/>
                <path fill="#FFFFFF" d="M56.6,60H21.1c-7,0-12.6-5-12.6-11.2V18c0-6.2,5.7-11.2,12.6-11.2h35.5c2.6,0,28.9-0.1,25.6,11.9
                  c1.6-5.9-13.2-6.8-13.2,3.8l0.3,26.4C69.2,55,63.6,60,56.6,60z"/>
                <path fill="none" stroke="#404041" strokeWidth="5" strokeLinecap="round" strokeMiterlimit="10" d="M49.4,29.9
                  c0-3.4,2.7-6.1,6.1-6.1c3.4,0,6.1,2.7,6.1,6.1"/>
                <path fill="none" stroke="#404041" strokeWidth="5" strokeLinecap="round" strokeMiterlimit="10" d="M17.3,29.7
                  c0-3.4,2.7-6.1,6.1-6.1s6.1,2.7,6.1,6.1"/>
                <path fill="#58595B" d="M42.3,60l14,0.1c7.3,0,12.9-4.5,12.9-11.4c0-6.3-0.1-8.1-0.1-8.1C61.6,40.6,42.3,52.2,42.3,60z"/>
                <path fill="#404041" d="M21.6,6.7c-10,0-22.6,4-21.5,11.5c-0.3-2.7,8.4-6.7,8.4,0.2l0,5.6c3.5,3.1,3.8-4.4,11.8-6.1
                  c8.1-1.6,9,3.3,18.8,3.3C52.1,21.2,51,8.7,45,6.7"/>
                <path fill="#FFAEEC" d="M29.5,54.6h19.1c5.1,0,9.3-4.2,9.3-9.3v0c0-5.1-4.2-9.3-9.3-9.3H29.5c-5.1,0-9.3,4.2-9.3,9.3v0
                  C20.2,50.4,24.4,54.6,29.5,54.6z"/>
                <circle fill="#404041" cx="29.7" cy="41.6" r="2.3"/>
                <circle fill="#404041" cx="49.4" cy="41.7" r="2.3"/>
                <path fill="#404041" d="M75.4,22.5c-0.2,0-2.1-1.8-2.1-1.8s-1.1,0.4-1.1,2.3c0,1.9,0.1,5.5,0.1,5.5c0,0.8,0.7,1.4,1.5,1.4H74
                  c0.8,0,1.4-0.6,1.4-1.4c0,0,0-0.6,0-3.4C75.3,22.2,75.4,22.5,75.4,22.5z"/>
                <path fill="#404041" d="M58.9,7.2c-0.1,1.5,1.7,2.9,4.1,3c2.3,0.1,4.3-1,4.4-2.6S65.7,2,63.4,1.9C61.1,1.8,59,5.7,58.9,7.2z"/>
                <path fill="#404041" d="M11.1,8.2c0.2,1.5,2.2,2.6,4.5,2.4c2.3-0.2,4.1-1.7,3.9-3.2c-0.2-1.5-2.5-5.6-4.8-5.4S10.9,6.6,11.1,8.2z"/>
              </svg>

              <svg style={{width: '25px', transform: 'translate(1px, -20px)'}} version="1.1"
                id="svg2" xmlnsDc="http://purl.org/dc/elements/1.1/" xmlnsRdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlnsSvg="http://www.w3.org/2000/svg"
                xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" 
                viewBox="2.3 2.3 14.2 12.6" enableBackground="new 2.3 2.3 13.9 12.6" xmlSpace="preserve">
              <path id="path4" fill="#FF0707" d="M9.3,5.1C8.5,3.9,7.6,2.5,5.9,2.3S2.3,3.7,2.3,5.7c0,4,2.2,4.6,7,9.1c4.8-4.6,7-5.2,7-9.1
                c0-2-2-3.7-3.6-3.4S10,3.9,9.3,5.1z"/>
              </svg>

            <svg style={{width: '85px', margin: '15px 0px -2px 20px', transform: 'rotate(10deg)'}} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="83.8 292.4 2834.6 2774.3" enableBackground="new 83.8 292.4 2834.6 2774.3" xmlSpace="preserve">
            <polygon fill="#FFFFFF" points="601.9,999.8 375.8,1647.8 741.2,1469.8 "/>
            <polyline fill="#00004C" points="120,3034.5 120,1932.8 120,704.4 1226.8,704.4 2459.6,704.4 2459.6,3034.5 "/>
            <polyline fill="#FEBC2D" points="120,3034.5 119.2,1849.9 609.5,323.1 953.5,1193.7 1185.8,805.2 1305.1,728.9 1506.1,1499.6 
              1674.5,1935.1 1982.7,2218.7 2459.6,2237.6 2459.6,3034.5 "/>
            <polyline fill="#141449" points="120,3034.5 770.3,2761.4 2069,2453.8 2459.6,2237.6 2459.6,3034.5 "/>
            <path fill="#FFFFFF" d="M1131.1,2774.6c0,0-631.5-664.7,0-426.3s910.2,68.4,1172.8-43.6c119.8-51,181-52.5,181-108.8l67.3-62.6
              c0,0,71,76.8,10.1,106.6c-35.9,17.2-70.3,36.6-89.7,63.9c-79.9,113-218.3,213.8-407.5,294.7
              C1778,2720.5,1533.7,2774.6,1131.1,2774.6z"/>
            <polygon fill="#FFFFFF" points="971.5,1350.7 604.5,314.5 710.8,317 1003.5,1271.5 "/>
            <polygon fill="#FFFFFF" points="971.5,1350.7 917.9,1199.3 1203.2,669.5 1277.1,719 "/>
            <rect x="823" y="1256.9" transform="matrix(0.3551 0.9348 -0.9348 0.3551 2180.8196 -546.1646)" fill="#FFFFFF" width="1326.6" height="101.3"/>
            <path fill="#FFFFFF" d="M2349.1,2303.3c-26.4,1.3-52.8,1.8-79.2,1c-92.1-3-179-19.7-259.2-49.4c-80.8-30.1-150.6-72.5-206.7-125.2
              c-58.9-55.6-106-112.1-129.7-184.2L1283.4,684.6l125.7,0.5c76,210.5,258.9,685.2,401,1018c72.3,169.5,129.8,308.3,165.8,344
              c128.6,127.6,166.6,139.8,242.4,159.7c75.8,20,194.6,24.3,241.3-3s-15.9,9.4,68-13.9l26.1,80.2
              C2486.3,2288.4,2417.6,2299.5,2349.1,2303.3z"/>
            <path fill="#141449" d="M2918.4,2090.8c0,95.1-77.2,172.3-172.3,172.3c-95.1,0-172.3-77.2-172.3-172.3
              c0-95.1,77.2-172.3,172.3-172.3C2841.2,1918.5,2918.4,1995.7,2918.4,2090.8"/>
            <path fill="#FFFFFF" d="M83.8,1934.4c74.4-32.2,156.7-52.8,239.6-42c97.5,12.6,225.7,35.9,374.6,161.8
              c66,55.9,136,120.1,209.1,175.4c73.1,55.2,149.6,100.8,228.1,118.8l-3.3,425.6c-595.5,12.2-602.6,274.8-633.1,292.7"/>
            <rect x="83.8" y="2003.1" fill="#FFFFFF" width="405.7" height="1063.5"/>
            <path fill="#141449" d="M1425.7,1751.5c-15.9-41.6-14.7-87,3.6-127.6c18-40.7,51-71.9,92.9-87.5c41.6-15.9,86.7-14.7,127.6,3.6
              c40.7,18,71.9,51,87.5,92.9l-80.5,30.4c-7.7-20.2-22.6-35.9-42.4-44.8c-19.7-8.6-41.5-9.4-61.5-1.6l0,0
              c-20.2,7.7-35.9,22.6-44.8,42.4c-8.6,19.7-9.4,41.5-1.6,61.5L1425.7,1751.5z"/>
            <rect x="1169.6" y="680.5" fill="#FFFFFF" width="94.5" height="23.9"/>
            
            <polygon fill="#FFFFFF" points="991.7,1288.9 678,475.9 826.5,435.2 1085.3,1118.2 "/>
            <polygon fill="#FFFFFF" points="119.2,1922.8 102.1,1917.2 100.9,1663.7 119.9,1606 516,292.4 609.5,323.1 "/>
            <path fill="#141449" d="M925.4,1945.9c-15.9-41.6-14.7-87,3.6-127.6c18-40.7,51-71.9,92.9-87.5c41.6-15.9,86.7-14.7,127.6,3.6
              c40.7,18,71.9,51,87.5,92.9l-80.5,30.4c-7.7-20.2-22.6-35.9-42.4-44.8c-19.7-8.6-41.5-9.4-61.5-1.6l0,0
              c-20.2,7.7-35.9,22.6-44.8,42.4c-8.6,19.7-9.4,41.5-1.6,61.5L925.4,1945.9z"/>
            <line fill="none" x1="1767.3" y1="1722.9" x2="850.4" y2="2076.5"/>
            <polygon fill="#FFFFFF" points="682.4,1043.4 513.6,1661 818.4,1516.1 "/>
            <line fill="none" x1="971.5" y1="1350.7" x2="119.2" y2="1922.8"/>
            </svg>
            
          </div>

          <br/>
            
            
            We’ve also made another site called <a href="https://www.physicsfox.org" target="_blank" className="link-pf">Physics Fox</a>, the best place on the web to learn physics. <br/> 
            <br/>
            <span style={{color: '#aaa'}}>(We like to think it is, anyway.)</span> {/* (We like to think so, anyway.)   (We think so anyway, but we're bias.)*/}
            
          
          <a href="https://www.physicsfox.org" target="_blank">
            <picture>
              <source srcset={mockupPFwebp} type="image/webp" />
              <img  id="mockup-pf" src={mockupPF} alt="An iPhone on the 'stars and light-years' page of PhysicsFox.org" />
            </picture> 
          </a>
            
            </div>
          
          <div className="rounded-box rb-pf-space" style={{paddingTop: '0px'}}>
          
          <picture>
            <source srcset={mockupPFqswebp} type="image/webp" />
            <img  id="mockup-bookmarks" src={mockupPFqs}  style={{width: '300px', marginBottom: '20px'}} alt="Various question cards of PhysicsFox.org" />
          </picture> 

            <span className="white-lighter">Physics Fox includes</span> hundreds of quiz questions <span className="white-lighter">to help you understand science!</span>
          <br/><br/>


          <div id="root3" onClick={constellationAnimationHomeDefault} className="flex-item-woof noSelect" style={{cursor: 'pointer', width: '270px',height: '100px', marginLeft: 'auto', marginRight: 'auto', transform: 'translateY(-30px) scale(1.1)',}}>

            <svg version="1.1" id="const-home-plough" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0.4 -9.8 841 273.9" enableBackground="new 0.4 -9.8 841 273.9" xmlSpace="preserve">   
              <line fill="none" stroke="#000000" strokeWidth="0.7347" strokeMiterlimit="10" x1="102.8" y1="147" x2="186.3" y2="95.4"/>
              <line fill="none" stroke="#000000" strokeWidth="0.7347" strokeMiterlimit="10" x1="226.9" y1="91.3" x2="271.1" y2="101.1"/>
              <line fill="none" stroke="#000000" strokeWidth="0.7347" strokeMiterlimit="10" x1="313.9" y1="109.2" x2="391.8" y2="120.2"/>
              <line fill="none" stroke="#000000" strokeWidth="0.7347" strokeMiterlimit="10" x1="426" y1="143.6" x2="462" y2="210.9"/>
              <line fill="none" stroke="#000000" strokeWidth="0.7347" strokeMiterlimit="10" x1="496.9" y1="222.1" x2="684.1" y2="172.6"/>
              <line fill="none" stroke="#000000" strokeWidth="0.7347" strokeMiterlimit="10" x1="706.2" y1="138.5" x2="713.5" y2="51.3"/>
              <g>
                  <circle stroke="#000000" strokeWidth="0.7347" strokeMiterlimit="10" cx="84.3" cy="158.7" r="7"/>
                  <polygon className="star" stroke="#000000" strokeWidth="0.7669" strokeMiterlimit="10" points="84.3,140 89.9,151.2 102.3,153.1 93.3,161.8 
                      95.4,174.2 84.3,168.4 73.1,174.2 75.3,161.8 66.3,153.1 78.7,151.2 	"/>
              </g>
              <g>
                  <circle stroke="#000000" strokeWidth="0.7347" strokeMiterlimit="10" cx="205.4" cy="85.6" r="7"/>
                  <polygon className="star" stroke="#000000" strokeWidth="0.7669" strokeMiterlimit="10" points="205.4,66.9 211,78.1 223.4,80 214.4,88.7 
                      216.5,101.1 205.4,95.3 194.2,101.1 196.4,88.7 187.4,80 199.8,78.1 	"/>
              </g>
              <g>
                  <circle stroke="#000000" strokeWidth="0.7347" strokeMiterlimit="10" cx="295.8" cy="105.3" r="7"/>
                  <polygon className="star" stroke="#000000" strokeWidth="0.7669" strokeMiterlimit="10" points="295.8,86.6 301.4,97.8 313.8,99.7 304.8,108.4 
                      306.9,120.8 295.8,115 284.6,120.8 286.8,108.4 277.8,99.7 290.2,97.8 	"/>
              </g>
              <g>
                  <circle stroke="#000000" strokeWidth="0.7347" strokeMiterlimit="10" cx="416.8" cy="120" r="7"/>
                  <polygon className="star" stroke="#000000" strokeWidth="0.7669" strokeMiterlimit="10" points="416.8,101.3 422.4,112.5 434.8,114.4 425.8,123.1 
                      427.9,135.5 416.8,129.7 405.6,135.5 407.8,123.1 398.8,114.4 411.2,112.5 	"/>
              </g>
              <g>
                  <circle stroke="#000000" strokeWidth="0.7347" strokeMiterlimit="10" cx="472.7" cy="227.4" r="7"/>
                  <polygon className="star" stroke="#000000" strokeWidth="0.7669" strokeMiterlimit="10" points="472.7,208.7 478.3,219.9 490.7,221.8 481.7,230.5 
                      483.8,242.9 472.7,237.1 461.5,242.9 463.7,230.5 454.7,221.8 467.1,219.9 	"/>
              </g>
              <g>
                  <circle stroke="#000000" strokeWidth="0.7347" strokeMiterlimit="10" cx="704.9" cy="164.4" r="7"/>
                  <polygon className="star" stroke="#000000" strokeWidth="0.7669" strokeMiterlimit="10" points="704.9,145.7 710.5,156.9 722.9,158.8 713.9,167.5 
                      716,179.9 704.9,174.1 693.7,179.9 695.9,167.5 686.9,158.8 699.3,156.9 	"/>
              </g>
              <g>
                  <circle stroke="#000000" strokeWidth="0.7347" strokeMiterlimit="10" cx="713.9" cy="30.1" r="7"/>
                  <polygon className="star" stroke="#000000" strokeWidth="0.7669" strokeMiterlimit="10" points="713.9,11.4 719.5,22.6 731.9,24.5 722.9,33.2 
                      725,45.6 713.9,39.8 702.7,45.6 704.9,33.2 695.9,24.5 708.3,22.6 	"/>
              </g>
            </svg>

          </div>


          <span className="white-lighter">By completing questions, you can</span> collect stars<span className="white-lighter">,</span> create constellations<span className="white-lighter">, and</span> customise your fox.


          <img src={fox} style={{position: 'relative', margin: '27px 0px 0px 73px', height: '150px', borderRadius: '50%'}} alt="Animated image of many cartoon foxes in different clothing"/> 

          </div>
          
          <div className="rounded-box">
          <span className="lighter">Much like Examoo,</span> Physics Fox is completely free. <br/>
          <span className="lighter">Aren't we nice.</span>
          
          <img src={mysteryGift} style={{width: '250px', padding: '35px 25px'}}/>

          <span className="lighter">Also, if you sign up today you'll get an</span> exclusive limited-edition hat. <span className="lighter"><br/>Shiny! Mysterious!</span>


          <div className="button-pf-parent">
            <a href="https://www.physicsfox.org" target="_blank">
            <div className="button-pf">

              <span style={{display: 'block', transform: 'translate(-12px, 10px)'}} >

                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  width="50px" viewBox="355.9 696.9 191.2 191.2" enableBackground="new 355.9 696.9 191.2 191.2"
                  xmlSpace="preserve">
                <g>
                  <path display="none" fill="#FFFFFF" d="M130.7,137.7c-4.2,0-8.4-2.5-9.9-6.9l-7.4-18.5l-5.4,17c-1.7,5.7-7.9,8.6-13.6,6.9
                    c-5.7-1.7-8.6-7.9-6.9-13.6L102,76.9c1.5-4.4,5.2-7.4,9.9-7.4s8.6,2.5,10.4,6.9l18.3,46.4c2.2,5.4-0.5,11.9-6.2,13.8
                    C133.4,137.7,131.9,137.7,130.7,137.7z"/>
                  <path display="none" fill="#FFFFFF" d="M157.8,156.7c-0.7-2.2-0.7-4.7,0.2-6.9c1-2.2,2.7-4,4.9-4.7s4.7-0.7,6.9,0.2
                    c2.2,1,4,2.7,4.7,4.9l-4.2,1.7c-0.5-1-1.2-2-2.2-2.5s-2.2-0.5-3.2,0l0,0c-1,0.5-2,1.2-2.5,2.2s-0.5,2.2,0,3.2L157.8,156.7z"/>
                  <path display="none" fill="#FEBC2D" d="M131.2,167.3c-0.7-2.2-0.7-4.7,0.2-6.9c1-2.2,2.7-4,4.9-4.7s4.7-0.7,6.9,0.2
                    c2.2,1,4,2.7,4.7,4.9l-4.2,1.7c-0.5-1-1.2-2-2.2-2.5s-2.2-0.5-3.2,0l0,0c-1,0.5-2,1.2-2.5,2.2s-0.5,2.2,0,3.2L131.2,167.3z"/>
                  <polyline display="none" fill="#FEBC2D" points="99.1,126.3 113.9,80.6 132.1,127 	"/>
                </g>
                <g>
                  <path display="none" fill="#FFFFFF" d="M489.8,131.1c-4.2,0-8.4-2.5-9.9-6.9l-7.4-18.5l-5.4,17c-1.7,5.7-7.9,8.6-13.6,6.9
                    s-8.6-7.9-6.9-13.6l14.6-45.7c1.5-4.4,5.2-7.4,9.9-7.4c4.7,0,8.6,2.5,10.4,6.9l18.3,46.4c2.2,5.4-0.5,11.9-6.2,13.8
                    C492.5,131.1,491,131.1,489.8,131.1z"/>
                  <path display="none" fill="#FFFFFF" d="M516.9,150.1c-0.7-2.2-0.7-4.7,0.2-6.9c1-2.2,2.7-4,4.9-4.7s4.7-0.7,6.9,0.2s4,2.7,4.7,4.9
                    l-4.2,1.7c-0.5-1-1.2-2-2.2-2.5s-2.2-0.5-3.2,0l0,0c-1,0.5-2,1.2-2.5,2.2s-0.5,2.2,0,3.2L516.9,150.1z"/>
                  <path display="none" fill="#FEBC2D" d="M490.3,160.7c-0.7-2.2-0.7-4.7,0.2-6.9c1-2.2,2.7-4,4.9-4.7c2.2-0.7,4.7-0.7,6.9,0.2
                    c2.2,1,4,2.7,4.7,4.9l-4.2,1.7c-0.5-1-1.2-2-2.2-2.5s-2.2-0.5-3.2,0l0,0c-1,0.5-2,1.2-2.5,2.2s-0.5,2.2,0,3.2L490.3,160.7z"/>
                  <polyline display="none" fill="#FEBC2D" points="458.2,119.7 473,74.1 491.3,120.5 	"/>
                </g>
                <rect x="355.9" y="696.9" fill="#FEBC2D" width="191.2" height="191.2"/>
                <g>
                  <polygon fill="#FFFFFF" points="415.3,746.9 403.5,781.5 423,771.8 	"/>
                  <path display="none" fill="#FFFFFF" d="M791.5,516.7c-4.2,0-8.4-2.5-9.9-6.9l-7.4-18.5l-5.4,17c-1.7,5.7-7.9,8.6-13.6,6.9
                    s-8.6-7.9-6.9-13.6l14.6-45.7c1.5-4.4,5.2-7.4,9.9-7.4c4.7,0,8.6,2.5,10.4,6.9l18.3,46.4c2.2,5.4-0.5,11.9-6.2,13.8
                    C794.2,516.7,792.7,516.7,791.5,516.7z"/>
                  <polyline fill="#FFFFFF" points="389.6,855.5 389.6,796.8 389.6,731.1 448.9,731.1 514.8,731.1 514.8,855.5 	"/>
                  <polyline fill="#FFFFFF" points="389.6,855.5 389.6,792.3 415.8,710.6 434.3,757.3 446.7,736.5 453.1,732.3 463.7,773.6 
                    472.9,796.8 489.4,812.1 514.8,813.1 514.8,855.5 	"/>
                  <polyline fill="#FFFFFF" points="387.7,857.3 425.7,841 497.3,824.7 514.8,810.9 514.8,857.3 	"/>
                  <g>
                    <path fill="#FEBC2D" d="M450.8,847.7c0,0-33.8-35.6,0-22.7c33.8,12.6,64-9.2,64-9.2s10.5,2,11,3.2s-0.5,0-2,0.7
                      c-0.5,0.2-1.2,2.5-1.5,2.7c-4.2,6.2-11.6,11.4-21.7,15.8C485.4,844.8,472.3,847.7,450.8,847.7z"/>
                    <path fill="#FEBC2D" d="M394.8,806.5c4-1.7,8.4-6.4,12.8-5.9c5.2,0.7,12.1,2,20,8.6c3.5,3,7.4,6.4,11.1,9.4
                      c3.7,3,7.9,5.4,12.1,6.4l-0.2,22.7c-31.9,0.7-32.1,14.6-33.8,15.6l-9.9-5.8L394.8,806.5z"/>
                  </g>
                  <path fill="#FFFFFF" d="M443.7,841.7c0,0-33.8-35.6,0-22.7c33.8,12.6,48.6,3.7,62.7-2.5c6.4-2.7,9.6-3,9.6-5.7c0,0,2.2,1,2.7,2.2
                    s-0.5,0-2,0.7c-0.5,0.2-1.6,1.9-1.9,2.1c-4.2,6.2-11.2,12-21.3,16.4C478.3,838.8,465.2,841.7,443.7,841.7z"/>
                  <polygon fill="#FEBC2D" points="435.3,765.7 415.6,710.1 421.2,710.4 437.1,761.2 	"/>
                  <polygon fill="#FEBC2D" points="435.3,765.7 432.4,757.5 447.7,729.1 451.6,731.8 	"/>
                  
                    <rect x="427.1" y="760.5" transform="matrix(0.3551 0.9348 -0.9348 0.3551 1011.7332 59.7784)" fill="#FEBC2D" width="70.9" height="5.4"/>
                  <path fill="#FEBC2D" d="M508.9,816.5c-1.5,0-3,0-4.2,0c-4.9-0.2-9.6-1-13.8-2.7c-4.4-1.7-8.1-4-11.1-6.7c-3.2-3-6.2-6.4-7.4-10.4
                    l-20.5-66.9h6.7c4,11.4,13.8,36.5,21.5,54.3c4,9.1,6.9,16.5,8.9,18.3c6.9,6.9,8.9,7.4,13.1,8.6c4,1,10.4,1.2,12.8-0.2l3.7-0.7
                    l1.5,4.2C516.3,815.8,512.6,816.3,508.9,816.5z"/>
                  <path fill="#FFFFFF" d="M539.3,805.2c0,5.2-4.2,9.1-9.1,9.1c-5.2,0-9.1-4.2-9.1-9.1c0-4.9,4.2-9.1,9.1-9.1
                    C535.1,796,539.3,800,539.3,805.2"/>
                  <path fill="#FFFFFF" d="M387.7,800.5c4-1.7,8.4-6.4,12.8-5.9c5.2,0.7,16.5-3.9,24.4,2.7c3.5,3,5.3,8.6,9,11.6
                    c3.7,3,5.6,9.1,9.8,10.1l-0.2,22.7c-31.9,0.7-34.6,14.6-36.3,15.6L387.7,800.5z"/>
                  <polygon fill="#FFFFFF" points="387.7,800.5 394.7,780.5 409.8,800.5 407.2,857.3 387.7,857.3 	"/>
                  <path display="none" fill="#FFFFFF" d="M818.6,535.7c-0.7-2.2-0.7-4.7,0.2-6.9c1-2.2,2.7-4,4.9-4.7s4.7-0.7,6.9,0.2
                    c2.2,0.9,4,2.7,4.7,4.9l-4.2,1.7c-0.5-1-1.2-2-2.2-2.5s-2.2-0.5-3.2,0l0,0c-1,0.5-2,1.2-2.5,2.2s-0.5,2.2,0,3.2L818.6,535.7z"/>
                  <polygon fill="#FEBC2D" points="436.3,762.2 419.5,718.8 427.4,716.8 441.2,753.1 	"/>
                  <polygon fill="#FEBC2D" points="386.9,805.7 386.9,794.6 388.7,782.5 389.6,779.2 410.9,709.1 415.8,710.6 	"/>
                  <path display="none" fill="#FEBC2D" d="M792,546.3c-0.7-2.2-0.7-4.7,0.2-6.9c1-2.2,2.7-4,4.9-4.7c2.2-0.7,4.7-0.7,6.9,0.2
                    c2.2,1,4,2.7,4.7,4.9l-4.2,1.7c-0.5-1-1.2-2-2.2-2.5s-2.2-0.5-3.2,0l0,0c-1,0.5-2,1.2-2.5,2.2s-0.5,2.2,0,3.2L792,546.3z"/>
                  <line fill="none" x1="477.8" y1="785.4" x2="428.9" y2="804.4"/>
                  <polygon fill="#FEBC2D" points="419.8,749.1 410.9,782.2 427.2,774.3 	"/>
                  <polyline display="none" fill="#FEBC2D" points="759.9,505.3 774.7,459.7 793,506.1 	"/>
                </g>
                <rect x="514.8" y="812.4" fill="#FEBC2D" width="4.6" height="7.8"/>
                <polygon display="none" fill="#FFFFFF" points="422.2,769.1 420.7,774.7 423.5,773.4 "/>
                <rect x="381.6" y="854.5" fill="#FEBC2D" width="34.3" height="7.1"/>
                </svg>

                <span className="button-pf-span" style={{display: 'inline-block', transform: 'translate(10px, -10px)'}}><span className="" style={{fontSize: '15px', fontWeight: '400'}}>Visit <br/></span> Physics Fox</span>
              </span>
            </div>
            </a>
          </div>

          </div>

        </div>

        <div className="legal-links">
          <span style={{opacity: '0.7', marginTop: '30px'}}>
          <a href="https://discord.gg/tc9TNjQvUp" target="_blank" style={{margin: '0 2px'}}> <img src={socialDiscord} alt="Discord Logo" style={{width: '34px', padding: '0px 3px', transform: 'translateY(-1.5px)'}} /></a>
          <a href="https://www.instagram.com/examoo.co.uk/" target="_blank" style={{margin: '0 3px'}}> <img src={socialInsta} alt="Instagram Logo" style={{width: '30px', padding: '0px 3px'}} /></a>
          <a href="https://www.tiktok.com/@examoo.co.uk" target="_blank" style={{margin: '0 3px'}}> <img src={socialTiktok} alt="Tiktok Logo" style={{width: '30px', padding: '0px 3px'}} /></a>
          </span>
          <br/>
          <NavLink to="/terms">Terms</NavLink> • <NavLink to="/privacy">Privacy</NavLink> • <NavLink to="/cookies">Cookies</NavLink>
          <br/>
          © 2022 Examoo. All rights reserved.
        </div>

      </div>

    </div>
    <br/>
    <br/>
    <br/>

    <Routes>
        <Route exact path="/terms" element={<Terms/>}/>
        <Route exact path="/privacy" element={<Privacy/>}/>
    </Routes>

    </>
  );
};
 
export default About;