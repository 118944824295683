import React, { useState, useEffect } from "react";
import createPersistedState from 'use-persisted-state';

import dropdown from "./dropdown.svg";
//import bookmarkOff from "./bookmark-off.svg";
import vertical from "./vertical-line.svg";

import Cookies from 'js-cookie';
 
function Bookmarks(props) {

	const [bookmarkArray, setBookmarkArray] = useState([]); // array of papertype objects.
	//const [bookmarksExist, setBookmarksExist] = useState(false); // array of papertype objects.
    const [runMany, setRunMany] = useState(0);
    const [beenSorted, setBeenSorted] = useState(false);

    //const [togTest, setTogTest] = useState(false);

	const useCookieMessageState = createPersistedState('cookie-message');
    const [cookiesMessage, setCookiesMessage] = useCookieMessageState(true);

    // gets object back from database
    // separate function from useEffect below, because JS doesn't like writing functions inside for loops.
    function findPT(key) {
        var papertype = props.paperTypeData.find(x => x.id === parseInt(key));
        var examBoard = props.examBoardData.find(x => x.id === parseInt(papertype.exam_board));
        var subject = props.subjData.find(x => x.id === parseInt(examBoard.subject));
        var hex_colour = props.colourData.find(x => x.id === parseInt(subject.colour)).hex_colour;
        var qual = props.qualData.find(x => x.id === parseInt(subject.qualification));
        //var region = props.regionData.find(x => x.id === parseInt(qual.subject));
        
        // add 'papertype_full' to object
        papertype.long_url = 'papers/uk/' + qual.url + '/' + subject.url + '/' + examBoard.url
        papertype.papertype_full = 'uk/' + qual.url + '/' + subject.url + '/' + examBoard.url + '/' + papertype.url + '/'; // add to object
        papertype.qual = qual.name;
        papertype.qual_order = qual.order;

        //papertype.subject = subject.name;

        // Fixing long subject names
        var subjname = subject.name;
        papertype.subj_order = subject.order;

        var subjnamesplit = subjname.split(' ');

        if (subjnamesplit.length >= 2) {
            papertype.subject = subjnamesplit[0].substring(0, 3).concat('') + ' ' + subjnamesplit[1].substring(0, 4);

        } else {

            // if two words, fist 3 letters of first word + first 4 of second. (English Lit, Lang.)
            if (subjname.length > 7) {
                papertype.subject = subjname.substring(0, 7).concat('.');
            } else {
                papertype.subject = subjname;
            }
        }


        // Fixing long exam board names
        var ebname = examBoard.name;
        //console.log('ebname: '+ebname);
        //papertype.subj_order = subject.order;

        var ebnamesplit = ebname.split('-');
        //console.log('ebnamesplit: '+ebnamesplit);

        if (subjnamesplit.length >= 1) {
            papertype.examBoard = ebnamesplit[0];

        } else {

            // if two words, fist 3 letters of first word + first 4 of second. (English Lit, Lang.)
            if (subjname.length > 7) {
                papertype.examBoard = ebname.substring(0, 7).concat('.');
            } else {
                papertype.examBoard = examBoard.name;
            }
        }

        //console.log('examBoard: '+papertype.examBoard);
        //console.log('');





        papertype.colour = hex_colour;

        papertype.opened = false;

        if (papertype.order === 0) { // 'All Papers' papertype always has order=0
            papertype.all_papers = true; 
            //console.log('all papers found!');
        } else {
            papertype.all_papers = false; 
        }

        setBookmarkArray(bookmarkArray => [...bookmarkArray, papertype]); // add current bookmark object to array
    
    };

    // puts LocalStorage into bookmarkArray:
	useEffect(() => {
        //console.log('bookmarkArray:');
        //console.log(bookmarkArray);

        // for bookmarked papertype in localstorage:
        for (var key in props.usePersStatesPaperType) {
            if ((props.usePersStatesPaperType[key][0] === true) && (key > 0)) { // avoid the 'all papers' one. (June: but 'all papers' still get through ??)
                //console.log('id: '+key); //id
                //console.log(props.usePersStatesPaperType[key]);
                findPT(key);
            }
            // create local-storage for each PaperType (e.g. GCSE Maths AQA Paper 2 Foundation)
            //bookmark_array.push(props.usePersStatesPaperType[grp]) = createPersistedState(props.paperTypeData[grp].name)( false );
        };

	}, []);



	useEffect(() => {
		document.title = "My Bookmarks • Examoo";
	}, []);


    function sortBookmarks() {
            // sort bookmarkArray:
        //console.log('sorting...')
        var temparray2 = [...bookmarkArray];

        //console.log('temparray2: '+temparray2)
        temparray2.sort((a, b) => a.qual_order - b.qual_order || a.subj_order - b.subj_order || a.examboard - b.examboard || a.order - b.order ); // || used for multiple sort
        setBookmarkArray(temparray2);
        setBeenSorted(true);
    }


	useEffect(() => {

        var blah = false;

        for (var key in props.usePersStatesPaperType) { 
            if ((props.usePersStatesPaperType[key][0] === true) && (key > 0)) { // avoid the 'all papers' one
                blah = true;
            }
        };

        if (blah === true) { // so don't get infinite run when there are no bookmarks saved 
            if (bookmarkArray[0]) {
                sortBookmarks();
            } else {
                setRunMany(runMany + 1);
                // keep doing this useEffect until bookmarkArray exists (only seems to need one run)
            }
    
            // timer for testing
            /*
            const timeout = setTimeout(() => {
            }, 2000)
    
            return () => clearTimeout(timeout)
            */
    
            //console.log('bookmarkArray:');
            //console.log(bookmarkArray);
        } else {
            setBeenSorted(true); // so don't get empty 'sorting' screen when no bookmarks are saved
        };
        
        //console.log('runMany:');
        //console.log(runMany);

	}, [runMany]);



    //const [bookmarkOpen, setBookmarkOpen] = useState(Array.from({length: 3}, (v, i) => false)); // array of whether each bookmark is open.

    /*
    function thingfunc(i) {
        var temparray = Array.from({length: Object.keys(props.usePersStatesPaperType).length}, (v, i) => false);
        temparray[i] = true;
        setBookmarkOpen(temparray);
    };
    */

    // toggle 'opened' element of bookmark in bookmarkArray (for opening and closing the bookmark)
    function toggleOpen(bmk_id) {
        var temparray = [...bookmarkArray];
        temparray.find(x => x.id === parseInt(bmk_id)).opened = !temparray.find(x => x.id === parseInt(bmk_id)).opened;
        setBookmarkArray(temparray); // add current bookmark object to array
    }

    function removeBookmark(bmk_id) {
        props.usePersStatesPaperType[bmk_id][1](false); // remove from cookies

        // remove from current bookmarkArray (so don't need to get entire array from cookies again)
        var temparray = bookmarkArray.filter(x => x.id !== parseInt(bmk_id)); // .filter() always needs to go to a new array
        setBookmarkArray(temparray); // add current bookmark object to array
    }



	var paperDict = { // get paper name from filename
		"none-pp": "Past* Paper",
		"none-qp": "Past* Paper",
		"none-ms": "Mark* Scheme",
		"pre": "Pre-release Material",
		"pp": "Past Paper",
		"qp": "Past Paper",
		"qpr": "Past Paper (R)",
		"qm": "Past Paper & MS",
		"qm2": "Past Paper & MS 2",
		"qm3": "Past Paper & MS 3",
		"ch": "Changes",
		"ch2": "More Changes",
		"chz": "Changes (.zip)",
		"cards": "Question Cards",
		"sample": "Sample",
		"ms": "Mark Scheme",
		"msr": "Mark Scheme (R)",
		"er": "Error Notice",
		"rep": "Exam Report",
		"repr": "Exam Report (R)",
		"com": "Commentary",
		"ins": "Insert",
		"per": "Periodic Table",
		"fo": "Formula Book",
		"sb": "Source Booklet",
		"bo": "Booklet",
		"ma": "Map",
		"anth": "Poetry Anthology",
		"in": "Insert",
		"da": "Data Sheet",
		"sc": "Score",
		"cc": "Role Play",
		"rp": "Role Play",
		"sf": "Sound File",
		"sfz": "Sound Files (.zip)",
		//"sf": "Sound Files (Separated)",
		"tr": "Transcript",
		"t3": "Transcript",
		"db": "Insert",
		"ex": "Example Responses",
		"ex2": "Example Responses 2",
		"ex3": "Example Responses 3",
		"notice": "Error Notice",
		"err": "Error Notice",
		"hint": "Exam Hints",
		"chk": "Revision Checklist",
		"tn": "Teachers' Notes",
		"maths-in-science": "Maths In Science",
		"neastudent": "NEA Student Booklet",
		"neateacher": "NEA Teacher Booklet",
		"nea": "NEA Marking Criteria",
		"t": "translation",
		"neacrit": "NEA Marking Criteria",
		"crit": "Assessment Criteria",
		"spec": "Specification",
		"specadd": "Specif. Addendum",
		"guid": "Question Guidance",
        "gloss": "Glossary",
        "sa": "Sample",
        "sa1": "Sample 1",
        "sa2": "Sample 2",
        "mssfz": "Mark Scheme (Audio)",
        "mo": "Movie",
        "dica": "Dictation A",
        "dicb": "Dictation B",
        "faq": "FAQ",
		"man": "Mandarin .mp3",
		"can": "Cantonese .mp3",
		"comm": "Command Words",
		"code": "Code Files",
	};

    if (beenSorted === false) return (
        <> 
        </>
    );

    return(
        <>
        <div style={{overflowX: 'hidden'}}>
            <div className="box">
            <div style={{display: bookmarkArray[0] ? 'none' : 'block', margin: '100px 0px 100px 0px'}}> {/* If bookmarks don't exist */}
                {/* <h2>Bookmarks!</h2> 
                <img rel="preload" src={bookmarkOff} style={{filter: 'invert(20%)', height: '150px'}} alt="" /> */}
                <svg style={{opacity: '0.2', height: '150px'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 41.2 60.5" xmlSpace="preserve">
                <path fill="#000000" d="M3.3,60.5c-0.8,0-1.6-0.3-2.2-0.9c-0.7-0.6-1-1.5-1-2.4V9.5C0,4.3,4.3,0,9.5,0h22.3c5.2,0,9.5,4.3,9.5,9.5
                    v47.7c0,1.8-1.5,3.3-3.3,3.3c-0.9,0-1.8-0.4-2.4-1.1L20.7,43.2L5.6,59.4C5,60.1,4.2,60.5,3.3,60.5z M9.5,4C6.5,4,4,6.5,4,9.5v45.9
                    l13.8-14.8c0.8-0.8,1.8-1.3,2.9-1.3c1.1,0,2.2,0.5,2.9,1.3l13.6,14.7V9.5c0-3-2.5-5.5-5.5-5.5H9.5z"/>
                </svg>

                <p style={{fontSize: '20px', margin: '20px auto', opacity: '0.5', width: '270px', lineHeight: '22px',}}>Press the bookmark button next to any paper to save it here!</p>
            </div>

            <div style={{display: bookmarkArray[0] ? 'block' : 'none', marginTop: '20px'}}> {/* If any bookmarks exist */}
                {/*
                {bookmarkOpen.map(thing => (
                    <div style={{background: thing ? 'green' : 'red'}}> a </div>
                ))}
                */}

                {bookmarkArray.map(bmk => (
                    <>
                    <div className="bookmark-box-together" >
                        <div className="bookmark-box-behind"></div>
                        <button className="bookmark-box" onClick={() => { toggleOpen(bmk.id) }} style={{background: bmk.colour.concat(60)}} role="checkbox" aria-checked={bmk.opened} aria-labelledby="chk-bmk-label" >
                            <span>
                                <div className="qual" style={{background: bmk.colour}}></div>
                                <div className="qual qual-dark">{bmk.qual}</div>
                                <div className="subject" style={{background: bmk.colour}}>{bmk.subject}</div>
                                <div className="exam-board" style={{background: bmk.colour.concat('60')}}>{bmk.examBoard}</div>
                            </span>

                            <span className="bmk-title">
                                <p key={bmk.id}>{bmk.name} <img className="dropdown-img" src={dropdown} style={{transform: bmk.opened ? 'translate(3px, -3px) rotate(180deg)' : 'translate(3px, -3px)'}} alt=". Dropdown icon"/>   </p>
                            </span>

                        </button>
                        
                    </div>



                    <div className="bmk-open-box"  style={{display: bmk.opened ? 'block' : 'none', background: bmk.colour}}>
                    
                        {bmk.all_papers 
                        ? 
                            <>

                            {/* Papers NOT Filtered (all-papers) */}
                            {props.paperTypeData.filter(item => (item.exam_board === bmk.exam_board && item.order !== 0)).map(item0 =>
                                <div className="papertype-box" style={{paddingBottom: '1px'}}>
                                    <div className="qual-title-papers">{item0.name}</div>

                                    {props.paperData.papers.filter(item => item.id === bmk.long_url).map(item =>
                                    <>
                                        {item.pprtypes.filter(item => item.pprtype === item0.url).map(item =>
                                        <>
                                            {item['yr-months'].map(item =>
                                            <>
                                                <div className="paper-pill-2-around">
                                                    <div key={item.id} className="paper-pill-2"> 
                                                    </div>
                                                </div>

                                                <div className="papers-slide-parent">
                                                        
                                                        {item.papers.map((it, index) =>
                                                            <div key={index} className="ppr"> 
                                                                <div>
                                                                <a href={it['url']} target="_blank">
                                                                        <div><img style={{width: '90%', marginTop: '4px'}} src={`${process.env.PUBLIC_URL}/img/`+it['name']+'.svg'} alt={bmk.qual+' '+bmk.subject+' '+bmk.examBoard+' '+bmk.name+' '+paperDict[it['name']]+' '+item.yr+' '+item.month} /></div> {/* {paperDict[it['name']]} */}
                                                                        <div></div>
                                                                        <div></div> 
                                                                </a>
                                                                </div>
                                                            </div>
                                                        )}
                                                </div>


                                                <div style={{background: '#00000080', width: '90%', margin: '0px auto 20px auto', color: 'white', height: '45px', position: 'relative', borderRadius: '0px 0px 20px 20px'}}>
                                                    
                                                    <span>
                                                        <span style={{position: 'absolute', left: '15px'}}>
                                                            <span>{item.yr}</span> 
                                                            <span className="month-style">{item.month.toUpperCase()}</span> 
                                                        </span>

                                                        <span style={{position: 'absolute', right: '15px'}}>
                                                            <span>
                                                                
                                                                <img style={{height: '30px', opacity: '0.3', transform: 'translate(16px, -6.5px)'}} src={vertical} alt="Small vertical line"/> 
                                                            </span>

                                                            <button onClick={ Cookies.get('rcl_consent_given') ?  () => { props.usePersStatesPaperYear[item.id][1]( !props.usePersStatesPaperYear[item.id][0] ) } : undefined} role="checkbox" aria-checked={props.usePersStatesPaperYear[item.id][0]} aria-labelledby="chk-tick-label-3">
                                                                
                                                                {props.usePersStatesPaperYear[item.id][0]
                                                                ?
                                                                <svg className="tick-onoff" style={{background: '#63D771'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                viewBox="0 0 75.8 56.9" xmlSpace="preserve">
                                                                    <rect x="12.6" y="15.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.0908 25.0116)" fill="#FFFFFF" width="15.2" height="41.8"/>
                                                                    <rect x="39.8" y="-4.2" transform="matrix(0.7071 0.7071 -0.7071 0.7071 33.986 -25.1457)" fill="#FFFFFF" width="15.2" height="65.3"/>
                                                                </svg>

                                                                :
                                                                <svg className="tick-onoff" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                viewBox="0 0 75.8 56.9" xmlSpace="preserve">
                                                                    <rect x="12.6" y="15.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.0908 25.0116)" fill="#FFFFFF" width="15.2" height="41.8"/>
                                                                    <rect x="39.8" y="-4.2" transform="matrix(0.7071 0.7071 -0.7071 0.7071 33.986 -25.1457)" fill="#FFFFFF" width="15.2" height="65.3"/>
                                                                </svg>

                                                                }
                                                            </button>
                                                            <label id="chk-tick-label-3" style={{display: 'none'}}>Toggle to save whether or not you have completed the papers from this year.</label>
                                                            

                                                        </span>
                                                    </span>
                                                </div>
                                            </>
                                            )}
                                        </>
                                        )}	
                                    </>
                                    )}

                                </div>
                            )}
                            </>

                        :
                            <div className="papertype-box">
                            {/* Papers ARE Filtered (e.g. 1-H) */}
                            {props.paperData.papers.filter(item => item.id === bmk.long_url).map(item =>
                                <>
                                {item.pprtypes.filter(item => item.pprtype === bmk.url).map(item =>
                                    <>
                                    {item['yr-months'].map(item =>
                                        <>
                                        <div className="paper-pill-2-around">
                                            <div key={item.id} className="paper-pill-2"> 
                                            </div>
                                        </div>

                                        <div className="papers-slide-parent">
                                                {item.papers.map((it, index) =>
                                                    <div key={index} className="ppr"> 
                                                        <div>
                                                        <a href={it['url']} target="_blank">
                                                                <div><img style={{width: '90%', marginTop: '4px'}} src={`${process.env.PUBLIC_URL}/img/`+it['name']+'.svg'}  alt={bmk.qual+' '+bmk.subject+' '+bmk.examBoard+' '+bmk.name+' '+paperDict[it['name']]+' '+item.yr+' '+item.month} /></div> {/* {paperDict[it['name']]} */}
                                                                <div></div>
                                                                <div></div> 
                                                        </a>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>


                                        <div style={{background: '#00000080', width: '90%', margin: '0px auto 20px auto', color: 'white', height: '45px', position: 'relative', borderRadius: '0px 0px 20px 20px'}}>
                                            
                                            <span>
                                                <span style={{position: 'absolute', left: '15px'}}>
                                                    <span>{item.yr}</span> 
                                                    <span className="month-style">{item.month.toUpperCase()}</span> 
                                                </span>

                                                <span style={{position: 'absolute', right: '15px'}}>
                                                    <span>
                                                        
                                                        <img style={{height: '30px', opacity: '0.3', transform: 'translate(16px, -6.5px)'}} src={vertical} alt="Small vertical line"/> 
                                                    </span>

                                                    
                                                    <button onClick={ Cookies.get('rcl_consent_given') ?  () => { props.usePersStatesPaperYear[item.id][1]( !props.usePersStatesPaperYear[item.id][0] ) } : undefined } role="checkbox" aria-checked={props.usePersStatesPaperYear[item.id][0]} aria-labelledby="chk-tick-label-2">
                                                        
                                                        {props.usePersStatesPaperYear[item.id][0]
                                                        ?
                                                        <svg className="tick-onoff" style={{background: '#63D771'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                        viewBox="0 0 75.8 56.9" xmlSpace="preserve">
                                                            <rect x="12.6" y="15.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.0908 25.0116)" fill="#FFFFFF" width="15.2" height="41.8"/>
                                                            <rect x="39.8" y="-4.2" transform="matrix(0.7071 0.7071 -0.7071 0.7071 33.986 -25.1457)" fill="#FFFFFF" width="15.2" height="65.3"/>
                                                        </svg>

                                                        :
                                                        <svg className="tick-onoff" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                        viewBox="0 0 75.8 56.9" xmlSpace="preserve">
                                                            <rect x="12.6" y="15.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.0908 25.0116)" fill="#FFFFFF" width="15.2" height="41.8"/>
                                                            <rect x="39.8" y="-4.2" transform="matrix(0.7071 0.7071 -0.7071 0.7071 33.986 -25.1457)" fill="#FFFFFF" width="15.2" height="65.3"/>
                                                        </svg>

                                                        }

                                                    </button>
                                                    <label id="chk-tick-label-2" style={{display: 'none'}}>Toggle to save whether or not you have completed the papers from this year.</label>
                                                    
                                                </span>
                                            </span>
                                        </div>
                                        </>
                                    )}
                                    </>
                                )}
                                </>
                            )}	
                            </div>
                        }
                        
                            <button className="remove-bmk-button" onClick={() => {removeBookmark(bmk.id)}}>
                                <span>
                                    <svg style={{width: '15px', transform: 'translate(-10px, 4.5px)'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 41.2 60.5" xmlSpace="preserve">
                                        <g>
                                            <path fill="#000000" d="M37,58L22.2,41.9c-0.8-0.9-2.1-0.9-2.9,0L4.2,58.1C3.4,58.9,2,58.4,2,57.2V9.5C2,5.3,5.3,2,9.5,2h22.3
                                                c4.1,0,7.5,3.3,7.5,7.5v47.7C39.2,58.3,37.8,58.9,37,58z"/>
                                            <path fill="#000000" d="M3.3,60.5c-0.8,0-1.6-0.3-2.2-0.9c-0.7-0.6-1-1.5-1-2.4V9.5C0,4.3,4.3,0,9.5,0h22.3c5.2,0,9.5,4.3,9.5,9.5
                                                v47.7c0,1.8-1.5,3.3-3.3,3.3c-0.9,0-1.8-0.4-2.4-1.1L20.7,43.2L5.6,59.4C5,60.1,4.2,60.5,3.3,60.5z M9.5,4C6.5,4,4,6.5,4,9.5v45.9
                                                l13.8-14.8c0.8-0.8,1.8-1.3,2.9-1.3c1.1,0,2.2,0.5,2.9,1.3l13.6,14.7V9.5c0-3-2.5-5.5-5.5-5.5H9.5z"/>
                                        </g>
                                    </svg>
                                </span>
                                <span style={{fontSize: '15px', width: 'auto'}}><b>Remove this bookmark</b></span>
                            </button> 
                    </div>

                    </>
                ))}

            <p style={{fontSize: '15px', width: '230px', margin: '25px auto 90px auto', opacity: '0.3'}}>
                
                <span style={{display: cookiesMessage ? 'block' : 'none', }}>
                    If you reset your cookies, you will lose your bookmarks! 
                    <br/> <button className="linky" onClick={ Cookies.get('rcl_consent_given') ?  () => {setCookiesMessage(false)} : undefined}>okay, got it</button> 
                </span>
            </p>
    
            </div>
            </div>

        </div>
        </>
    );
}
 
export default Bookmarks;