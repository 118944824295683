import React, { useEffect } from "react";


function Privacy() {

    useEffect(() => {
        document.title = "Privacy Policy • Examoo";
    }, []);

    return (
      <>
      <h1 style={{textAlign: 'center', background: '#bb0000', padding: '10px 0', color: 'white'}}>Privacy Policy</h1>
      
      <div>
        {/* <p style={{margin: '10px', textAlign: 'center'}}><i>A summary of this document, written for young people, is available <a href="{% url 'privacy-summary' %}">here</a>.</i></p> */}
        <p style={{textAlign: 'center'}}>Last updated: 18th May 2022</p>
        <div style={{textAlign: 'left', marginTop: '20px', marginBottom: '30px', padding: '0 20px 10px 20px', overflowWrap: 'break-word'}}>
          <p className="legal-title">
            1.	Introduction
          </p><p>
            Welcome to Examoo.co.uk.
          </p><p>
            Examoo.co.uk (“<b>us</b>”, “<b>we</b>”, or “<b>our</b>”) operates Examoo.co.uk and Examoo mobile application (hereinafter referred to as “<b>Service</b>”).
          </p><p>
            Our Privacy Policy governs your visit to Examoo.co.uk and Examoo mobile application, and explains how we collect, safeguard and disclose information that results from your use of our Service.
          </p><p>
            We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
          </p><p>
            Our <a href="../terms">Terms and Conditions</a> (“<b>Terms</b>”) govern all use of our Service and together with the Privacy Policy constitutes your agreement with us (“<b>agreement</b>”).
          </p>
          <p className="legal-title">
            2.	Definitions
          </p><p>
            <b>SERVICE</b> means the Examoo.co.uk website and Examoo mobile application operated by Examoo.co.uk.
          </p><p>
            <b>PERSONAL DATA</b> means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
          </p><p>
            <b>USAGE DATA</b> is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).
          </p><p>
            <b>COOKIES</b> are small files stored on your device (computer or mobile device).
          </p><p>
            <b>DATA CONTROLLER</b> means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.
          </p><p>
            <b>DATA PROCESSORS</b> (OR SERVICE PROVIDERS) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.
          </p><p>
            <b>DATA SUBJECT</b> is any living individual who is the subject of Personal Data.
          </p><p>
            <b>THE USER</b> is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.
          </p>
          <p className="legal-title">
            3.	Our Contact Details
          </p><p>
            Name: Tom Quincey <br />
            Email address: ExamooContact@gmail.com <br />
            Postal address: Office 47218, PO Box 6945, London, W1A 6US <br />
          </p>
          <p className="legal-title">
            4.	Information Collection and Use
          </p><p>
            We collect several different types of information for various purposes to provide and improve our Service to you.
          </p>
          {/* <p>
            Most of the personal information we process is provided to us directly by you in order to create an Account on our Service.
          </p> */}
          <p>
            Under the General Data Protection Regulation (GDPR), the lawful bases we rely on for processing this information are: 
          </p><p>
            (a)	your consent. You are able to remove your consent at any time. You can do this by emailing ExamooContact@gmail.com;
          </p><p>
            (b)	we have a contractual obligation;
          </p><p>
            (c)	we have a legal obligation;
          </p><p>
            (d)	we have a legitimate interest to collect your data to streamline our services and improve them.
          </p>
          <p className="legal-title">
            5.	Types of Data Collected
          </p><p>
            <b>Personal Data</b><br />
            While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“<b>Personal Data</b>”). Personally identifiable information may include, but is not limited to:
          </p><p>
            (a)	Email address
          </p><p>
            (b)	First name and last name
          </p><p>
            (c)	The country you live in
          </p><p>
            (d)	Cookies and Usage Data
          </p><p>
            We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link in any newsletter.
          </p><p>
            <b>Usage Data</b><br />
            We may also collect information that your browser sends whenever you visit our Service or when you access Service by or through a mobile device (“<b>Usage Data</b>”).
          </p><p>
            This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
          </p><p>
            When you access Service with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.
          </p><p>
            <b>Tracking Cookies Data</b><br />
            We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.
          </p><p>
            Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.
          </p><p>
            You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
          </p><p>
            Examples of Cookies we use:
          </p><p>
            (a)	<b>Necessary  Cookies</b>: We use Necessary  Cookies to operate our Service, including for security purposes.
          </p><p>
            (b)	<b>Preference Cookies</b>: We use Preference Cookies to remember your preferences and various settings.
          </p><p>
            (c)	<b>Marketing Cookies</b>: Marketing Cookies are used to serve you with advertisements that may be relevant to you and your interests.
          </p><p>
            (d)	<b>Statistics Cookies</b>: Statistics Cookies are used to track the traffic on our website. For more information, please read “13. Analytics” below.
          </p><p>
            For more information about Cookies, please visit our <a href="../cookies">Cookie Policy</a> on the website (www.examoo.co.uk/cookies).
          </p>
            {/*
          <p>
            <b>Social Media Login Data</b>
          </p><p>
            Our Service offers you the ability to register and login using your third-party social media account details, such as your Google or Facebook logins. Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, profile picture, and possibly other information you choose to make public on such social media platform. 
          </p><p>
            We will use the information we receive only for the purposes that are described in this privacy notice. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.
          </p><p>
            Google’s privacy policy: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>
          </p><p>
            Facebook’s privacy policy: <a href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a>
          </p>
            */}
          <p className="legal-title"> 
            6.	Use of Data
          </p><p>
            Examoo.co.uk uses the collected data for various purposes:
          </p><p>
            (a)	to provide and maintain our Service;
          </p><p>
            (b)	to notify you about changes to our Service; 
          </p><p>
            (c)	to allow you to participate in interactive features of our Service when you choose to do so; 
          </p><p>
            (d)	to provide customer support; 
          </p><p>
            (e)	to gather analysis or valuable information so that we can improve our Service; 
          </p><p>
            (f)	to monitor the usage of our Service;
          </p><p>
            (g)	to detect, prevent and address technical issues;
          </p><p>
            (h)	to fulfill any other purpose for which you provide it;
          </p><p>
            (i)	to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;
          </p><p>
            (j)	to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.;
          </p><p>
            (k)	to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;
          </p><p>
            (l)	to facilitate account creation and logon process;
          </p><p>
            (m)	to manage user accounts;
          </p><p>
            (n)	to post testimonials. We may post testimonials on our Service that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and the content of the testimonial. If you wish to update, or delete your testimonial, please contact us at ExamooContact@gmail.com and be sure to include your name and testimonial; 
          </p><p>
            (o) to post comments. We may post comments on our Service that will contain your display name. Prior to posting a comment, we will obtain your consent to use your display name and the content of the comment. If you wish to update, or delete your comment, please contact us at ExamooContact@gmail.com and be sure to include your display name and comment;
          </p><p>
            (p)	request feedback; We may use your information to request feedback and to contact you about your use of our Service;
          </p><p>
            (q)	in any other way we may describe when you provide the information;
          </p><p>
            (r)	for any other purpose with your consent. 
          </p><p>
            <b>Retention of Data</b>
          </p><p>
            Your Personal Data is securely stored within Europe.
          </p><p>
            We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
          </p><p>
            We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.
          </p><p>
            You may request deletion of your {/* Account and its associated */} Personal Data by emailing us at ExamooContact@gmail.com
          </p>
          <p className="legal-title"> 
            7.	Transfer of Data
          </p><p>
            Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
          </p><p>
            If you are located outside of Europe and choose to provide information to us, please note that we transfer the data, including Personal Data, to Europe and process it there.
          </p><p>
            Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
          </p><p>
            Examoo.co.uk will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.
          </p>
          <p className="legal-title">
            8.	Disclosure of Data
          </p><p>
            We may disclose personal information that we collect, or you provide:
          </p><p>
            (a)	<b>Disclosure for Law Enforcement.</b> <br />
            Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.
          </p><p>
            (b)	<b>Business Transaction.</b> <br />
            If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.
          </p><p>
            (c)	<b>Other cases. We may disclose your information also:</b> <br />
          </p><p>
            (i)	for any other purpose disclosed by us when you provide the information;
          </p><p>
            (ii)	with your consent in any other cases;
          </p><p>
            Note that we do not share or sell your Personal Information to third parties for marketing purposes.
          </p>
          <p className="legal-title">
            9.	Security of Data
          </p><p>
            The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
          </p><p>
            We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
          </p>
          <p className="legal-title">
            10.	Your Data Protection Rights Under General Data Protection Regulation (GDPR)
          </p><p>
            If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights, covered by GDPR. – See more at <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">https://eur-lex.europa.eu/eli/reg/2016/679/oj</a>
          </p><p>
            We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.
          </p><p>
            These data protection rights include:
          </p><p>
            (a)	the right to access. You have the right to ask us for copies of your personal information;
          </p><p>
            (b)	the right of rectification. You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete;
          </p><p>
            (c)	the right to erasure. You have the right to ask us to erase your personal information in certain circumstances;
          </p><p>
            (d)	the right to restriction of processing. You have the right to ask us to restrict the processing of your personal information in certain circumstances;
          </p><p>
            (e)	the right to object to processing. You have the the right to object to the processing of your personal information in certain circumstances;
          </p><p>
            (f)	the right to data portability. You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances;
          </p><p>
            (g)	the right to withdraw consent. You also have the right to withdraw your consent at any time where we rely on your consent to process your personal information;
          </p><p>
            Please note that we may ask you to verify your identity before responding to such requests. Please note, we may not able to provide Service without some necessary data.
          </p><p>
            You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).
          </p><p>
            You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you.
          </p><p>
            If you wish to make a request, please email us at ExamooContact@gmail.com.
          </p>
          <p className="legal-title">
            11.	Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)
          </p><p>
            CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law’s reach stretches well beyond California to require a person or company in the United States (and conceivable the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy. – See more at: <a href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/">https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/</a> 
          </p><p>
            According to CalOPPA we agree to the following:
          </p><p>
            (a)	users can visit our site anonymously;
          </p><p>
            (b)	our Privacy Policy link includes the word “Privacy”, and can easily be found on the page specified above on the home page of our website;
          </p><p>
            (c)	users will be notified of any privacy policy changes on our Privacy Policy Page;
          </p><p>
            (d)	users are able to change their personal information by emailing us at ExamooContact@gmail.com. 
          </p><p>
            We not not utilise “Do Not Track” signals.
          </p>
          <p className="legal-title">
            12.	Service Providers
          </p><p>
            We may employ third party companies and individuals to facilitate our Service (“<b>Service Providers</b>”), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.
          </p><p>
            These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
          </p>
          <p className="legal-title">
            13.	Analytics
          </p><p>
            We may use third-party Service Providers to monitor and analyze the use of our Service.
          </p><p>
            <b>Google Analytics</b> <br />
            Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.
          </p><p>
            For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>
          </p><p>
            We also encourage you to review the Google's policy for safeguarding your data: <a href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a>.
          </p><p>
            <b>Cloudflare analytics</b> <br />
            Cloudflare analytics is a web analytics service operated by Cloudflare Inc. Read the Privacy Policy here: <a href="https://www.cloudflare.com/privacypolicy/">https://www.cloudflare.com/privacypolicy/</a> 
          </p>
          <p className="legal-title">
            14.	CI/CD tools
          </p><p>
            We may use third-party Service Providers to automate the development process of our Service. 
          </p><p>
            <b>GitHub</b>
          </p><p>
            GitHub is provided by GitHub, Inc.
          </p><p>
            GitHub is a development platform to host and review code, manage projects, and build software.
          </p><p>
            For more information on what data GitHub collects for what purpose and how the protection of the data is ensured, please visit GitHub Privacy Policy page: <a href="https://help.github.com/en/articles/github-privacy-statement">https://help.github.com/en/articles/github-privacy-statement</a>.
          </p>
          <p className="legal-title">
            15.	Advertising
          </p><p>
            We may use third-party Service Providers to show advertisements to you to help support and maintain our Service.
          </p><p>
            <b>Google AdSense DoubleClick Cookie</b> <br />
            Google, as a third party vendor, uses cookies to serve ads on our Service. Google's use of the DoubleClick cookie enables it and its partners to serve ads to our users based on their visit to our Service or other websites on the Internet.
          </p><p>
            You may opt out of the use of the DoubleClick Cookie for interest-based advertising by visiting the Google Ads Settings web page: <a href="http://www.google.com/ads/preferences/">http://www.google.com/ads/preferences/</a>
          </p><p>
            <b>AdMob by Google</b> <br />
            AdMob by Google is provided by Google Inc.
          </p><p>
            You can opt-out from the AdMob by Google service by following the instructions described by Google: <a href="https://support.google.com/ads/answer/2662922?hl=en">https://support.google.com/ads/answer/2662922?hl=en</a>
          </p><p>
            For more information on how Google uses the collected information, please visit the “How Google uses data when you use our partners' sites or app” page: <a href="http://www.google.com/policies/privacy/partners/">http://www.google.com/policies/privacy/partners/</a> or visit the Privacy Policy of Google: <a href="http://www.google.com/policies/privacy/">http://www.google.com/policies/privacy/</a>
          </p>
          <p className="legal-title">
            16.	Behavioral Remarketing
          </p><p>
            Examoo.co.uk may use remarketing services to advertise on third party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimise and serve ads based on your past visits to our Service.
          </p><p>
            <b>Google Ads (AdWords)</b> <br />
            Google Ads (AdWords) remarketing service is provided by Google Inc.
          </p><p>
            You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page: <a href="http://www.google.com/settings/ads">http://www.google.com/settings/ads</a>
          </p><p>
            Google also recommends installing the Google Analytics Opt-out Browser Add-on – <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a> – for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.
          </p><p>
            For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>
          </p>
          <p className="legal-title">
            17.	Payments
          </p><p>
            We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g. payment processors).
          </p><p>
            We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
          </p><p>
            The payment processors we work with are:
          </p><p>
            <b>PayPal or Braintree:</b> <br />
            Their Privacy Policy can be viewed at <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full">https://www.paypal.com/webapps/mpp/ua/privacy-full</a>
          </p><p>
            <b>Stripe:</b> <br />
            Their Privacy Policy can be viewed at: <a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a>
          </p>
          <p className="legal-title">
            18.	Links to Other Sites
          </p><p>
            Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
          </p><p>
            We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
          </p>
          <p className="legal-title">
            19.	Children's Privacy 
          </p><p>
            User Accounts on our Service are intended only for access and use by individuals at least thirteen (13) years old. By accessing or using any of Company, you warrant and represent that you are at least thirteen (13) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms. If you are not at least thirteen (13) years old, you are prohibited from both the access and usage of Service.
          </p><p>
            Note that this minimum age may be higher than thirteen (13) years old in some European countries due to local data protection laws. For example, individuals must be at least sixteen (16) years old in Ireland. To sign up for an Account on our Service, you must be above the age of data consent in your country. You are responsible for knowledge of the relevant age of data consent for your country.
          </p><p>
            We do not knowingly collect or store any Personal Data from children under the age of data consent. If we find out that any Personal Data we have collected is from someone under the age of data consent, we will terminate their Account.
          </p><p>
            If you are between the age of data consent and 18 years old, you must have permission from a parent or guardian in order to create an Account. You and your parent(s) or guardian(s) must review these Terms together.
          </p><p>
            If a parent or guardian alerts us that their child has misrepresented their age when creating an Account on our Service, we will terminate the Account, provided we have satisfactory information to determine that the reporting person is a legitimate parent or guardian of the child.
          </p>
          <p className="legal-title">
            20.	Changes to This Privacy Policy
          </p><p>
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
          </p><p>
            We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update “Last updated” at the top of this Privacy Policy.
          </p><p>
            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
          </p>
          <p className="legal-title">
            21.	Contact Us
          </p><p>
            If you have any questions or concerns about this Privacy Policy, please contact us at: 
          </p><p>
            Name: Tom Quincey<br />
            Email address: ExamooContact@gmail.com<br />
            Postal address: Office 47218, PO Box 6945, London, W1A 6US
          </p><p>
            You can also complain to the Information Commissioner’s Office
            (ICO) if you are unhappy with how we have used your data. We would, however, appreciate the opportunity to deal with your concerns before you approach the ICO, so please contact us in the first instance.
          </p><p>
            The ICO’s address:<br />           
            Information Commissioner’s Office<br />
            Wycliffe House<br />
            Water Lane<br />
            Wilmslow<br />
            Cheshire<br />
            SK9 5AF
          </p><p>
            Helpline number: 0303 123 1113 <br />
            ICO website: https://www.ico.org.uk
          </p>
        </div>
        <br /><br />
      </div>
      </>
    );
};
 
export default Privacy;