import React, { useEffect } from "react";


function Cookies() {

    useEffect(() => {
        document.title = "Cookie Policy • Examoo";
    }, []);

    return (
      <>
      <h1 style={{textAlign: 'center', background: '#009900', padding: '10px 0', color: 'white'}}>Cookie Policy</h1>
      
      <div>
        <p style={{textAlign: 'center'}}>Last updated: 18th May 2022</p>
        <div style={{textAlign: 'left', marginTop: '20px', marginBottom: '30px', padding: '0 20px 10px 20px', overflowWrap: 'break-word'}}>
          <p className="legal-title">
            What are cookies?
          </p>
            <p>
            Cookies are small text files which are transferred to your computer or phone when you visit a website or app. They are used to remember information about you — that way you don't need to keep entering it again and again.
            </p>

            <p>
            Below is a list all the cookies used in this website, and what they are used for. Please also read our privacy policy at <a href="../privacy">www.examoo.co.uk/privacy</a>. {/* Feel free to change your cookies settings at any time. */}
            </p>


        <p className="legal-title">
            Necessary Cookies
        </p>
            <p>
            Necessary Cookies are required to make the website usable and secure. None of our necessary cookies are third-party cookies.
            </p>

            <p>
            <b>csrftoken</b> <br/>
            CSRF tokens are secret, user-specific tokens used in order to make a website more secure (by preventing Cross-Site Request Forgeries).
            </p>

            <p>
            <b>rcl_consent_given</b>, <b>rcl_preferences_consent</b>, <b>rcl_statistics_consent</b>, <b>rcl_marketing_consent</b> <br/>
            These are the cookies that remember which (non-necessary) cookies you have consented to. How meta!
            </p>

            <p>
            <b>Session Storage</b> <br/>
            A strictly necessary cookie that is essential to navigate a website. GDPR exempts these cookies from consent requirements as they do not gather any personal information about users. 
            </p>

            <p>
            <b>sessionid</b> <br/>
            We use this cookie to assign a session to the user currently using the site. That way, if there are multiple tabs of our site open, we still know it is being used by the same user.
            </p>

            <p>
            <b>Local Storage</b> <br/>
            This is used to remember the bookmarks you have saved (by clicking the bookmark button), and which exams you have marked as finished (by clicking the tick button). This means if you come back to the site another time, your bookmarks completed exams and will be remembered! 
            <br/><br/>
            Also used to remember if the message "If you reset your cookies, you will lose your bookmarks!" has been dismissed. (This message is visible on the Bookmarks page once a bookmark has been created).
            </p>



        <p className="legal-title">
            Preference Cookies
        </p>
            <p>
            Preference cookies enable a website to remember information that changes the way the website behaves or looks.
            </p>

            <p>
            There are currently <b>no preference cookies</b>.
            </p>



        <p className="legal-title">
            Statistics Cookies
        </p>
            <p>
            Statistics Cookies are third-party cookies used to anonymously track the traffic on our website.
            </p>

            <p>
            <b>_ga</b> [x2]<br/>
            Google analytics cookies. These register a unique ID that is used to generate statistical data on how a visitor uses the website.
            </p>


        <p className="legal-title">
            Marketing Cookies
        </p>
            <p>
            Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.
            </p>

            <p>
            There are currently <b>no marketing cookies</b>.
            </p>


            <p style={{fontSize: "14px", textAlign: "center"}}>
                <br/>
                <b>Contact details:</b>
                <br/>Email address: examoocontact@gmail.com
                <br/>Postal address: Office 47218, PO Box 6945, London, W1A 6US
            </p>
        </div>
        <br /><br />
      </div>
      </>
    );
};
 
export default Cookies;